import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import type { TaskWithGuideInfo } from "backend/resources/planEntry";
import type { ServiceEngagement } from "backend/resources/services/serviceEngagement";
import { supabase } from "clients/supabaseClient";
import { useActiveOrganizationIds } from "state/organization/organization";
import { fetchAllDataInChunks } from "utils";
import type { Database } from "../../../../types/supabase";

const TABLE = "service_resource";
export type ServiceResource =
  Database["public"]["Tables"]["service_resource"]["Row"];
export type ServiceResourceUpdate =
  Database["public"]["Tables"]["service_resource"]["Update"];
export type ServiceResourceWithServiceEngagements = ServiceResource & {
  service_engagement: ServiceEngagement[];
};
const SERVICE_RESOURCE_PLAN_ENTRY_TABLE = "service_resource_plan_entry";
export type ServiceResourcePlanEntry =
  Database["public"]["Tables"]["service_resource_plan_entry"]["Row"];

export function useServiceResources() {
  const activeOrgIds = useActiveOrganizationIds();
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, { activeOrgIds }],
    queryFn: async () => {
      if (!activeOrgIds) return null;
      const queryBuilder = supabase
        .from(TABLE)
        .select("*, service_engagement(*)")
        .in("organization_id", activeOrgIds)
        .order("created_at", { ascending: false });

      return (
        (await fetchAllDataInChunks<ServiceResourceWithServiceEngagements>(
          queryBuilder
        )) ?? []
      );
    },
  });
}

export function useServiceResource(
  serviceResourceEventId: string | undefined | null
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, serviceResourceEventId],
    queryFn: async () => {
      if (!serviceResourceEventId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .eq("id", serviceResourceEventId)
        .limit(1)
        .maybeSingle();

      return data;
    },
  });
}

export function useUpsertServiceResourceAndPlanEntry() {
  const upsertServiceResource = useUpsertServiceResource();
  const upsertServiceResourcePlanEntry = useSetServiceResourcePlanEntry();

  return useMutation({
    mutationFn: async (upsertData: {
      serviceResourceUpdate: ServiceResourceUpdate;
      plan_entry_id?: string;
    }) => {
      const upsertDataObject = {
        plan_entry_id: upsertData.plan_entry_id,
        serviceResourceUpdate: upsertData.serviceResourceUpdate,
      };
      const { data: serviceResourceData } =
        await upsertServiceResource.mutateAsync(
          upsertDataObject.serviceResourceUpdate
        );
      if (serviceResourceData && upsertData.plan_entry_id) {
        await upsertServiceResourcePlanEntry.mutateAsync({
          service_resource_id: serviceResourceData.id,
          plan_entry_id: upsertData.plan_entry_id,
        });
        return serviceResourceData;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceResourcePlanEntry],
      });
    },
  });
}

export function useUpsertServiceResource() {
  const activeOrgIds = useActiveOrganizationIds();

  return useMutation({
    mutationFn: async (
      upsertData: ServiceResourceUpdate & {
        organization_id?: string | undefined;
      }
    ) => {
      if (!upsertData.listing_id || !upsertData.name) throw new Error();

      let organizationId = upsertData.organization_id;

      if (!organizationId) {
        if (!activeOrgIds) {
          throw new Error("No active organization id");
        }

        if (activeOrgIds.length > 1) {
          throw new Error("Multiple active organization ids");
        }

        // If there is only one active organization, use that
        [organizationId] = activeOrgIds;
      }

      return supabase
        .from(TABLE)
        .upsert(
          {
            ...upsertData,
            organization_id: upsertData.organization_id ?? organizationId,
          } as ServiceResource,
          { onConflict: "listing_id,organization_id" }
        )
        .select("*")
        .limit(1)
        .maybeSingle();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
    },
  });
}
export function useUpdateServiceResource() {
  return useMutation({
    mutationFn: async (serviceResourceUpdate: ServiceResourceUpdate) => {
      if (!serviceResourceUpdate.id) return null;
      return supabase
        .from(TABLE)
        .update(serviceResourceUpdate)
        .eq("id", serviceResourceUpdate.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceResourcePlanEntry],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
    },
  });
}

export function useServiceResourcesFromPlanEntry(planEntryId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResourcePlanEntry, { planEntryId }],
    queryFn: async () => {
      if (!planEntryId) return null;
      const { data, error } = await supabase
        .from(SERVICE_RESOURCE_PLAN_ENTRY_TABLE)
        .select("service_resource(*)")
        .eq("plan_entry_id", planEntryId)
        .limit(1)
        .maybeSingle();

      return data?.service_resource;
    },
  });
}

export function useServiceRequestsFromServiceResource(
  serviceResourceId?: string
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResourcePlanEntry, { serviceResourceId }],
    queryFn: async () => {
      if (!serviceResourceId) return null;
      const { data, error } = await supabase
        .from(SERVICE_RESOURCE_PLAN_ENTRY_TABLE)
        .select(
          `
          plan_entry(
            *,
            guide_task(
              guide_category(
                *
              ),
              guide_sub_category(
                id
              )
            ),
            user!plan_entry_user_id_fkey(
              first_name,
              last_name
            ),
            network(
              name
            ),
            service_resource_plan_entry(
              *,
              service_resource(
                *,
                service_engagement(
                  *
                )
              )
            )
          )
        `
        )
        .eq("service_resource_id", serviceResourceId);

      return (data?.map((row) => row.plan_entry) ?? []) as TaskWithGuideInfo[];
    },
  });
}

export function useServiceResourceByCategory(category: string) {
  const activeOrgIds = useActiveOrganizationIds();
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, { category, activeOrgIds }],
    queryFn: async () => {
      if (!category || !activeOrgIds) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .textSearch("categories", category)
        .in("organization_id", activeOrgIds);
      return data;
    },
  });
}

export function useSetServiceResourcePlanEntry() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (upsertData: {
      service_resource_id: string;
      plan_entry_id: string;
    }) =>
      supabase
        .from(SERVICE_RESOURCE_PLAN_ENTRY_TABLE)
        .upsert(upsertData, { onConflict: "plan_entry_id" }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceResourcePlanEntry],
      });
    },
  });
}
