import { LoadingSpinner } from "components/LoadingSpinner";
import Tabs from "components/Tabs/Tabs";
import { useAuthUser } from "features/users/auth";
import useMFA from "hooks/useMFA/useMFA";
import { ExternalRoute } from "lib/routing";
import { useEffect, useMemo, useState } from "react";
import { resetAssessmentStore } from "state/assessment";
import MFAPhone from "./MFAPhone";
import MFATOTP from "./MFATOTP";

export enum MFATabs {
  Phone = "Phone",
  TOTP = "TOTP",
}

const MFA = () => {
  const { logout } = useAuthUser();
  const [tab, setTab] = useState<MFATabs | string>("");
  const [MFAOptions, setMFAOptions] = useState<string[]>([]);
  const [showMFA, setShowMFA] = useState<boolean>(false);

  const { factorsList } = useMFA();

  const hasTOTPFactor = useMemo(
    () =>
      factorsList?.some(
        (factor) =>
          factor.factor_type === "totp" && factor.status === "verified"
      ),
    [factorsList]
  );

  const hasPhoneFactor = useMemo(
    () =>
      factorsList?.some(
        (factor) =>
          factor.factor_type === "phone" && factor.status === "verified"
      ),
    [factorsList]
  );

  async function handleLogout(e: any) {
    e.stopPropagation();
    resetAssessmentStore();
    logout();
  }

  useEffect(() => {
    if (!factorsList) return;
    if (showMFA) return;
    setShowMFA(false);

    if (hasTOTPFactor && !hasPhoneFactor) {
      setMFAOptions([MFATabs.TOTP, MFATabs.Phone]);
    } else if (hasPhoneFactor && !hasTOTPFactor) {
      setMFAOptions([MFATabs.Phone, MFATabs.TOTP]);
    } else {
      setMFAOptions([MFATabs.TOTP, MFATabs.Phone]);
    }

    const timer = setTimeout(() => setShowMFA(true), 1000);
    return () => clearTimeout(timer);
  }, [hasTOTPFactor, hasPhoneFactor, showMFA]);

  const memoizedMFAOptions = useMemo(() => MFAOptions, [MFAOptions]);

  return (
    <main className="h-full w-full space-y-6 p-6 flex flex-col items-center pt-12 bg-neutral-50">
      <header className="">
        <h2 className="text-xl md:text-3xl truncate font-medium w-full md:w-auto text-center md:text-left">
          Verify Your Identity
        </h2>
        <p className="text-gray-500 text-center md:text-left">
          Choose a method to verify your identity
        </p>
      </header>

      {showMFA ? (
        <section className="w-96 md:w-[480px] flex justify-center flex-col items-center border rounded-2xl shadow-sm py-6 bg-white">
          <Tabs
            tabs={memoizedMFAOptions}
            currentTab={tab}
            setCurrentTab={setTab}
            className="w-full justify-evenly font-medium text-sm"
          />
          <section className="w-full bg-white">
            {tab === MFATabs.TOTP && (
              <>
                {hasTOTPFactor ? (
                  <MFATOTP />
                ) : hasPhoneFactor ? (
                  <p className="p-4 text-sm text-center">
                    You already have Multifactor Authentication enabled via Text
                    Messages. To add this second factor, please navigate to your
                    profile and select ‘MFA Settings’.
                  </p>
                ) : (
                  <MFATOTP />
                )}
              </>
            )}
            {tab === MFATabs.Phone && (
              <>
                {hasPhoneFactor ? (
                  <MFAPhone />
                ) : hasTOTPFactor ? (
                  <p className="p-4 text-sm text-center">
                    You already have Multifactor Authentication enabled via the
                    Authentication App. To add this second factor, please
                    navigate to your profile and select ‘MFA Settings’.
                  </p>
                ) : (
                  <MFAPhone />
                )}
              </>
            )}
          </section>
        </section>
      ) : (
        <section>
          <LoadingSpinner className="h-10 w-10" />
        </section>
      )}

      <section>
        <button
          className="text-blue-500 hover:underline"
          onClick={handleLogout}>
          Cancel
        </button>
      </section>

      <section>
        <span className="text-center sm:text-left text-xs sm:text-sm flex gap-2 item-center">
          <a
            className="text-blue-500 hover:text-blue-800"
            href={ExternalRoute.TERMS_OF_SERVICE}>
            Terms of Service
          </a>
          |
          <a
            className="text-blue-500 hover:text-blue-800"
            href={ExternalRoute.PRIVACY_POLICY}>
            Privacy Policy
          </a>
        </span>
      </section>
    </main>
  );
};

export default MFA;
