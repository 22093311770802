import { useOrgs } from "backend/resources/orgRole";
import {
  OrganizationInvitationStatus,
  useOrganizationInvitationQuery,
} from "backend/resources/organizationInvitations/organizationInvitation";
import type { OrgRoleType } from "backend/resources/userRole/types";
import { OrgRoleTypeToLabel } from "backend/resources/userRole/types";
import DeleteInvitationButton from "components/AdminPage/DeleteInvitationButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import DeactivateMemberButton from "components/EditMemberButtons/EditMemberButton";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useActiveOrganizationIds } from "state/organization/organization";

export default function MembersTab({
  setFocusedOrgMemberId,
}: {
  setFocusedOrgMemberId: any;
}) {
  const navigate = useAppNavigate();
  const organizationIds = useActiveOrganizationIds();
  const { organizationInvitations } = useOrganizationInvitationQuery();
  const { allOrgIdentities } = useOrgs();
  const { authUser } = useAuthUser();
  const { data: user } = useFetchOne(
    { equals: { id: authUser?.id } },
    { enabled: !!authUser }
  );

  const orgRoles = allOrgIdentities?.filter((organizationRole) =>
    organizationIds?.includes(organizationRole.organization_id)
  );

  const filteredInvitations = organizationInvitations?.filter(
    (invitation) =>
      invitation.status === OrganizationInvitationStatus.PENDING &&
      organizationIds?.includes(invitation.organization_id)
  );

  return (
    <div className="gap-2 flex flex-col pb-10 w-full">
      <div className="flex flex-col">
        {/* headers */}
        <div className="pb-2 gap-4 grid grid-cols-[2fr,1fr,1fr,.5fr] w-full items-center text-xs md:text-base">
          <p className="font-medium">Name</p>
          {/* <p className="font-medium">Email</p> */}
          <p className="font-medium text-center">Role</p>
          <p className="font-medium text-center">Status</p>
          <p className="min-w-[56px]"></p>
        </div>
        {/* me row */}
        {orgRoles?.find((role) => role.user_id === user?.id) ? (
          <div className="grid grid-cols-[4fr,.5fr] gap-2">
            <div className="gap-4 grid grid-cols-[2fr,1fr,1fr] w-full  text-xs md:text-base">
              {user?.first_name ? (
                <p className="overflow-wrap break-words items-center">{`${user?.first_name} ${user?.last_name}`}</p>
              ) : (
                <p>Me</p>
              )}
              {/* <p>{user?.email}</p> */}
              <p className="text-center line-clamp-1">
                {
                  OrgRoleTypeToLabel[
                    orgRoles.find((role) => role.user_id === user?.id)
                      ?.role as OrgRoleType
                  ]
                }
              </p>
              <p className="text-center">Active</p>
            </div>
            <p className="min-w-[56px]"></p>
          </div>
        ) : null}
      </div>
      {/* all invitations */}
      {filteredInvitations?.map((invitation) => (
        <div
          className="grid grid-cols-[4fr,.5fr] gap-2"
          key={invitation.id}>
          <button
            onClick={() =>
              navigate({
                path: SharedRoute.VIEW_INVITATION,
                params: {
                  id: invitation.id,
                },
                queryParams: {
                  is_for_organization: String(true),
                },
              })
            }
            className={`gap-4 grid grid-cols-[2fr,1fr,1fr] w-full  items-center text-xs md:text-base py-2  hover:bg-neutral-50`}>
            <div className="flex justify-start">{`${invitation.first_name} ${invitation?.last_name}`}</div>
            <div className="text-center line-clamp-1">
              {OrgRoleTypeToLabel[invitation.role_type as OrgRoleType]}
            </div>
            <div className="flex justify-center">Pending</div>
          </button>
          <div className="flex justify-start min-w-[56px]">
            <DeleteInvitationButton
              invitationId={invitation.id}
              isOrgInvite
            />
          </div>
        </div>
      ))}

      {/* active org roles */}
      {orgRoles
        ?.filter((orgRole) => orgRole.user_id !== authUser?.id)
        .sort((a, b) =>
          (a.user?.first_name || "").localeCompare(b.user?.first_name || "")
        )
        ?.map((orgRole) => (
          <div
            className="grid grid-cols-[4fr,.5fr] gap-2"
            key={orgRole.id}>
            <button
              className="gap-4 grid grid-cols-[2fr,1fr,1fr] w-full py-2 text-xs md:text-base hover:bg-neutral-50"
              onClick={() => setFocusedOrgMemberId(orgRole.user_id)}>
              <p className="text-start overflow-wrap break-words items-center line-clamp-1">{`${orgRole.user?.first_name} ${orgRole.user?.last_name}`}</p>
              <p className="text-center line-clamp-1">
                {OrgRoleTypeToLabel[orgRole.role as OrgRoleType]}
              </p>
              <p className="text-center">
                {orgRole.is_deactivated ? "Disabled" : "Active"}
              </p>
            </button>
            <div className="flex items-center gap-2 ">
              <DeactivateMemberButton role={orgRole} />
              <ButtonWithIcon
                onClick={() =>
                  navigate({
                    path: SharedRoute.TEAM_EDIT_TEAM_MEMBER,
                    params: {
                      id: orgRole.id,
                    },
                    queryParams: {
                      is_for_organization: String(true),
                    },
                  })
                }
                disabled={orgRole.is_deactivated}
                text={""}
                size={"small"}
                icon={IconOption.EDIT}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
