import BillingPage from "components/BillingPage/BillingPage";
import { CarespacePage } from "components/CarespacePage";
import { PAFSubmissionPage } from "components/CarespacePage/PAFSubmission/PAFSubmissionPage";
import DirectoryPage from "components/DirectoryPage/DirectoryPage";
import DiscussionsPage from "components/DiscussionsPage/DiscussionPage";
import CreateOrEditGoalTemplatePage from "components/GoalPage/GoalTemplate/CreateOrEditGoalTemplatePage";
import GoalTemplatePage from "components/GoalPage/GoalTemplate/GoalTemplatePage";
import CreateOrEditShareableRecommendationPage from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/CreateOrEditShareableRecommendationPage";
import CreateOrEditShareableInterventionPage from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableIntervention/CreateOrEditShareableInterventionPage";
import ShareableInterventionPage from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableIntervention/ShareableInterventionPage";
import ShareableRecommendationPage from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendationPage";
import GuideCategoryPage from "components/GuideCategoryPage/GuideCategoryPage";
import OrganizationPage from "components/OrganizationPage/OrganizationPage";
import { PageContainer } from "components/PageContainer";
import TaskNavigatorPage from "components/TaskNavigatorPage/TaskNavigatorPage";

import NewTask from "features/tasks/pages/new";
import ViewTask from "features/tasks/pages/view";

import ServiceHub from "features/service-resources/pages/service-hub";

import CarespaceList from "features/carespaces/pages/list";

import OrganizationsList from "features/organizations/pages/list";
import OrganizationsNew from "features/organizations/pages/new";

import MembersList from "features/members/pages/list";
import MembersNew from "features/members/pages/new";

import { CareCentralRoute, SuperSuperUserRoute } from "lib/routing";
import "react-loading-skeleton/dist/skeleton.css";
import type { RoutLayoutObject } from "./routeTypes";
import { RouteLayout } from "./routeTypes";

export const careCentralRoutes: RoutLayoutObject[] = [
  {
    path: CareCentralRoute.PAF_SUBMISSION_VIEW,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: (
      <PageContainer>
        <PAFSubmissionPage isReadOnly={true} />
      </PageContainer>
    ),
  },
  {
    path: CareCentralRoute.PAF_SUBMISSION_EDIT,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: (
      <PageContainer>
        <PAFSubmissionPage isReadOnly={false} />
      </PageContainer>
    ),
  },
  {
    path: CareCentralRoute.GUIDE_NAVIGATOR,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <TaskNavigatorPage />,
  },
  {
    path: CareCentralRoute.CARESPACES,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CarespaceList />,
  },
  {
    path: CareCentralRoute.SERVICE_HUB,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <ServiceHub />,
  },
  {
    path: CareCentralRoute.BILLING_PAGE,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <BillingPage />,
  },
  {
    path: CareCentralRoute.VIEW_TASK,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <ViewTask />,
  },
  {
    path: CareCentralRoute.NEW_TASK,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <NewTask />,
  },
  {
    path: CareCentralRoute.GUIDE_CATEGORY,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <GuideCategoryPage />,
  },
  {
    path: CareCentralRoute.DISCUSSIONS,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <DiscussionsPage />,
  },
  {
    path: CareCentralRoute.ORG,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <OrganizationPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditShareableRecommendationPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD_ID,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditShareableRecommendationPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_RECOMMENDATION,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <ShareableRecommendationPage />,
  },
  {
    path: CareCentralRoute.DIRECTORY,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <DirectoryPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_INTERVENTION,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <ShareableInterventionPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditShareableInterventionPage />,
  },
  {
    path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD_ID,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditShareableInterventionPage />,
  },
  {
    path: CareCentralRoute.CARESPACE,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CarespacePage />,
  },
  {
    path: CareCentralRoute.GOAL_TEMPLATE_ID,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <GoalTemplatePage />,
  },
  {
    path: CareCentralRoute.GOAL_TEMPLATE_EDIT,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditGoalTemplatePage />,
  },
  {
    path: CareCentralRoute.GOAL_TEMPLATE_EDIT_ID,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <CreateOrEditGoalTemplatePage />,
  },
  {
    path: SuperSuperUserRoute.ORGANIZATIONS,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <OrganizationsList />,
  },
  {
    path: SuperSuperUserRoute.ORGANIZATIONS_NEW,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <OrganizationsNew />,
  },
  {
    path: SuperSuperUserRoute.MEMBERS,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <MembersList />,
  },
  {
    path: SuperSuperUserRoute.MEMBERS_NEW,
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    element: <MembersNew />,
  },
];
