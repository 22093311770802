import { zodResolver } from "@hookform/resolvers/zod";
import { signupWithInvitation } from "backend/functions";
import { ExternalRoute } from "lib/routing";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Text } from "shared/ui/text";
import { z } from "zod";

const formSchema = z.object({
  password: z
    .string()
    .min(10, { message: "Password must be at least 8 characters" }),
});

type FormSchema = z.infer<typeof formSchema>;

export default function AcceptInvitation() {
  const [isWaitingForConfirmationEmail, setIsWaitingForConfirmationEmail] =
    useState(false);
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });
  const { toast } = useToast();
  const queryParams = new URLSearchParams(window.location.search);
  // Get the invitation_id from the URL
  const invitationId = queryParams.get("invitation_id");
  // we need to make it lower case since python sets it as True
  const isForOrganization =
    queryParams
      .get("isForOrganization")
      ?.toLocaleLowerCase()
      .includes("true") ?? false;

  async function onSubmit(data: FormSchema) {
    const result = await signupWithInvitation(
      data.password,
      invitationId,
      isForOrganization
    );

    if (result?.error) {
      toast({
        title: "Error",
        description: result.error,
        variant: "destructive",
      });
    } else {
      setIsWaitingForConfirmationEmail(true);
    }
  }

  if (!invitationId) {
    return <div>Invalid invitation link</div>;
  }

  if (isWaitingForConfirmationEmail) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Card
          title="Invitation accepted"
          className="w-[450px]">
          <Text>
            Please check your email and click the link provided to complete your
            registration.
          </Text>
          <br />
          <Text>It may take a few minutes for the email to arrive.</Text>
          <br />
          <Text>You can now close this window.</Text>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card
        title={`You've been invited to join ${
          isForOrganization ? "an organization" : "a caregiving team"
        }`}
        className="w-[450px]">
        <Form
          form={form}
          onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="password"
            label="Please create a password"
            className="mt-4"
            render={({ field }) => (
              <Input
                type="password"
                placeholder="Password"
                {...field}
              />
            )}
          />

          <Button
            type="submit"
            className="w-full mb-4"
            isLoading={form.formState.isSubmitting}>
            Submit
          </Button>
        </Form>
        <Text className="transition-colors [&>a]:text-brand-orange [&>a]:hover:brightness-110">
          By signing up, you agree to the{" "}
          <a
            href={ExternalRoute.TERMS_OF_SERVICE}
            target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href={ExternalRoute.PRIVACY_POLICY}
            target="_blank">
            Privacy Policy
          </a>{" "}
          , including{" "}
          <a
            href={ExternalRoute.COOKIES_POLICY}
            target="_blank">
            Cookie Use
          </a>
          .
        </Text>
      </Card>
    </div>
  );
}
