/**
 * @fileoverview Sets up the Zustand product store.
 * This store holds information about the product state.
 */
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "backend/query-keys";
import { useActiveNetwork } from "backend/resources/network/network";
import type { Organization, OrganizationRole } from "backend/resources/orgRole";
import { useOrgs } from "backend/resources/orgRole";
import { useActiveUserRole } from "backend/resources/userRole";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import { useActiveOrganizationIds } from "state/organization/organization";

export enum CraniometrixProduct {
  CARE_PILOT = "care pilot",
  CARE_CENTRAL = "care central",
}

/**
 * Product access and URL state
 */

export function useProductAccess() {
  const { userRole, isUserRoleLoading } = useActiveUserRole();

  const { isLoading: isHasCareCentralAccessLoading, hasCareCentralAccess } =
    useOrgs();

  return {
    data: getCurrentProduct(hasCareCentralAccess, !!userRole),
    isLoading: isHasCareCentralAccessLoading || isUserRoleLoading,
  };
}

function getCurrentProduct(
  hasCareCentralAccess: boolean,
  hasUserRole: boolean
) {
  if (hasCareCentralAccess) return CraniometrixProduct.CARE_CENTRAL;
  else if (hasUserRole) return CraniometrixProduct.CARE_PILOT;
  else return undefined;
}

/**
 * Self-contained hook for checking if the user has CareBinder control
 *
 * @memo: we tried consolidating this but it was difficult to coordinate the org and orgRole loading states,
 * perhaps we can revisit this in the future but for now... coders beware
 */
// TODO fix this mess
// TODO: PR-2794: Remove CareBinder completely
export function useHasPlanBinderControl() {
  const { authUser } = useAuthUser();
  const activeOrgIds = useActiveOrganizationIds();
  const { data: networkData } = useActiveNetwork();
  const { isSuperSuperUser } = useOrgs();

  return useQuery({
    queryKey: [QUERY_KEYS.hasCarebinderControl, { activeOrgIds }],
    queryFn: () => {
      if (isSuperSuperUser) {
        return true;
      }

      if (networkData?.organization_id) {
        return fetchHasCareBinderControl(
          networkData.organization_id,
          authUser?.id
        );
      }

      if (!activeOrgIds) {
        return false;
      }

      if (activeOrgIds.length > 1) {
        throw new Error("Multiple organizations are not supported");
      }

      return fetchHasCareBinderControl(activeOrgIds[0], authUser?.id);
    },
    refetchOnWindowFocus: false,
  });
}

async function fetchHasCareBinderControl(
  organization_id: string | undefined | null,
  user_id?: string | undefined | null
) {
  if (!user_id || !organization_id) return false;

  const { data: organizationRole, error } = await supabase
    .from("organization_role")
    .select("*")
    .eq("organization_id", organization_id)
    .eq("user_id", user_id)
    .returns<(OrganizationRole & { organization: Organization })[]>()
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return organizationRole !== undefined;
}
