import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import group from "lodash/groupBy";
import startCase from "lodash/startCase";
import { useMemo, useState } from "react";

import { SharedRoute, useAppNavigate } from "lib/routing";

import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";

import { Avatar } from "shared/ui/avatar";
import { Badge } from "shared/ui/badge";
import { PieChart } from "shared/ui/pie-chart";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";

import {
  SERVICE_STATUS_COLORS,
  ServiceStatus,
  TASK_STATUS_COLORS,
  TaskStatus,
} from "features/tasks/constants";
import { formatStatus, formatWho } from "features/tasks/display-formatters";
import { useFetchMany } from "features/tasks/queries/hooks";
import { type Task } from "features/tasks/types";
import { useAuthUser } from "features/users/auth";

const columnHelper = createColumnHelper<Task>();

export default function ServiceRequests() {
  const { authUser } = useAuthUser();
  const navigate = useAppNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const {
    data: serviceRequests = [],
    isLoading,
    isError,
  } = useFetchMany({
    equals: {
      is_service_ticket: true,
    },
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Task Name",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("network.name", {
        header: "Carespace",
        meta: {
          columnFiltering: {
            filterLabel: "Carespace",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Request Status",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge color={TASK_STATUS_COLORS[value]}>{startCase(value)}</Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Request Status",
            formatOptionLabel: (value) => startCase(value),
          },
        },
      }),
      columnHelper.accessor("scheduled_date_time", {
        header: "Due",
        cell: (ctx) => {
          const value = ctx.getValue();

          return value ? format(value, "MM/dd/yyyy") : "N/A";
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor(
        (task) => `${task.user?.first_name} ${task.user?.last_name}`,
        {
          id: "who",
          header: "Who",
          cell: (ctx) => (
            <div className="flex flex-row items-center gap-2">
              <Avatar
                firstName={ctx.row.original.user?.first_name || ""}
                lastName={ctx.row.original.user?.last_name || ""}
                profileImage={ctx.row.original.user?.profile_image || ""}
              />
              <Text>{formatWho(ctx.row.original, authUser?.id)}</Text>
            </div>
          ),
          meta: {
            columnFiltering: {
              filterLabel: "Who",
            },
          },
        }
      ),
      columnHelper.accessor("service_status", {
        header: "Service Status",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge color={SERVICE_STATUS_COLORS[value]}>
              {value === ServiceStatus.NotApplicable ? "N/A" : startCase(value)}
            </Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Service Status",
            formatOptionLabel: (value) =>
              value === ServiceStatus.NotApplicable ? "N/A" : startCase(value),
          },
        },
      }),
      columnHelper.accessor("created_at", {
        header: "Created",
        cell: (ctx) => {
          const value = ctx.getValue();

          return value ? format(value, "MM/dd/yyyy") : "N/A";
        },
        enableColumnFilter: false,
      }),
    ],
    [authUser?.id]
  );

  if (isLoading) {
    <LoadingSpinner className="h-6 w-6" />;
  }

  if (isError) {
    return <Text>Sorry, something went wrong. Please try again.</Text>;
  }

  return (
    <div className="flex flex-col gap-4">
      <ButtonWithIcon
        size={"small"}
        onClick={() => navigate({ path: SharedRoute.SERVICE_REQUEST_NEW })}
        text={"Create Service Request"}
        icon={IconOption.PLUS}
      />

      <DataTable
        columns={columns}
        data={serviceRequests}
        onRowClick={(row) =>
          navigate({
            path: SharedRoute.SERVICE_REQUEST_VIEW,
            params: {
              id: row.id,
            },
          })
        }
        columnFiltering={{
          columnFilters,
          setColumnFilters,
          renderContent: (filteredRows) => {
            const groups = group(filteredRows, (row) =>
              formatStatus(row.original)
            );

            const percentComplete = Math.floor(
              (filteredRows.filter(
                (row) => row.original.status === TaskStatus.Done
              ).length /
                filteredRows.length) *
                100
            );

            return (
              <PieChart
                title={`Request Status (${percentComplete || 0}% complete)`}
                groups={Object.entries(groups).map(([label, tasks]) => ({
                  label,
                  count: tasks.length,
                  color: tasks[0].original.is_overdue
                    ? "red"
                    : TASK_STATUS_COLORS[tasks[0].original.status],
                }))}
              />
            );
          },
        }}
      />
    </div>
  );
}
