import { useEffect } from "react";

import { supabase } from "clients/supabaseClient";

import { useToast } from "shared/hooks/use-toast";
import { useAuthUser } from "./auth-user-context";

export function useAuthListener() {
  const { toast } = useToast();
  const { setAuthUser } = useAuthUser();

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setAuthUser(
          session
            ? {
                id: session.user.id,
                email: session.user.email || "",
              }
            : undefined
        );
      })
      .catch(() => {
        toast({
          title: "Sorry, something went wrong",
          description: "Please try logging in again.",
        });
      });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setAuthUser(
        session
          ? {
              id: session.user.id,
              email: session.user.email || "",
            }
          : undefined
      );
    });

    return () => subscription.unsubscribe();
  }, [setAuthUser, toast]);
}
