import PhoneIcon from "assets/phone-icon.svg?react";
import SendInvitationIcon from "assets/send-invitation.svg?react";
import { useCreatePrivateConversationMutation } from "backend/functions";
import { useNetworksInOrganization } from "backend/resources/network/network";
import type { NetworkRoleType } from "backend/resources/userRole/types";
import { NetworkRoleTypeToLabel } from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ProfileImage } from "components/Profile/ProfileImage";
import { Select } from "components/Select";
import {
  ListType,
  RoleTableFields,
  UserRoleTable,
} from "components/Tables/UserRoleTable";
import { useOrgAndCarespaceIdentitiesForUser } from "hooks/orgMember/orgMember";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useNetworkStore } from "state/network/network";

// user detail view

type DMOptions = {
  selectedCarespaceId?: string;
};

export function UserDetailView({
  userId,
  setUserId,
  DMOptions,
}: {
  userId: string;
  setUserId: (value: string | null) => void;
  DMOptions?: DMOptions;
}) {
  return (
    <ResponsiveModal
      isOpen={!!userId}
      closeText="Back"
      onClose={() => setUserId(null)}
      title={undefined}>
      <OrgMemberDetailComponent
        userId={userId}
        DMOptions={DMOptions}
      />
    </ResponsiveModal>
  );
}

function OrgMemberDetailComponent({
  userId,
  DMOptions,
}: {
  userId: string;
  DMOptions?: DMOptions;
}) {
  const navigate = useAppNavigate();

  const { data, isLoading } = useOrgAndCarespaceIdentitiesForUser(userId);

  const { data: productAccess } = useProductAccess();

  const [isCarespaceDMModalOpen, setIsCarespaceDMModalOpen] = useState(false);

  const createPrivateConversation =
    useCreatePrivateConversationMutation().mutateAsync;

  async function handleDMClick() {
    if (DMOptions?.selectedCarespaceId) {
      const extConversationId = await createPrivateConversation({
        network_id: DMOptions.selectedCarespaceId,
        user_id: userId,
      });
      navigate({
        path: CarePilotRoute.CONVERSATION,
        params: { conversation_id: extConversationId },
      });
    } else {
      setIsCarespaceDMModalOpen(true);
    }
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    );
  } else {
    return (
      <div className="max-h-[75vh] flex flex-col w-full items-center gap-5 px-10 pb-10 font-light">
        <CarespaceSelectionForDMModal
          isOpen={isCarespaceDMModalOpen}
          onClose={() => setIsCarespaceDMModalOpen(false)}
          userIdToDM={userId}
        />
        {/* name */}
        <h1 className="text-3xl">
          {data?.first_name} {data?.last_name}
        </h1>

        <ProfileImage
          className="w-16 h-16"
          userId={userId}
        />

        {/* info  */}
        <div className="flex flex-col gap-3 w-full">
          {/* email */}
          <a
            href={`mailto:${data?.email}`}
            className="button flex w-full gap-3 text-lg">
            <SendInvitationIcon className="w-6 h-6" />
            {data?.email}
          </a>
          {/* phone */}
          <a
            href={`tel:${data?.cell_number}`}
            className="button flex w-full gap-3 text-lg">
            <PhoneIcon className="w-6 h-6" />
            {data?.cell_number}
          </a>
          {data?.organization_role &&
            data?.organization_role.length > 0 &&
            productAccess === CraniometrixProduct.CARE_CENTRAL && (
              <ButtonWithIcon
                onClick={handleDMClick}
                text="Send Message"
                icon={IconOption.MESSAGE}
                size={"small"}
              />
            )}
        </div>
        {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
          <>
            {/* organization */}
            <div className="flex flex-col w-full">
              <h2 className="text-xl">Organization(s)</h2>
              <UserRoleTable
                data={
                  data?.organization_role.map((org_role) => ({
                    [RoleTableFields.WorkSpaceName]:
                      org_role.organization?.name ?? "",
                    [RoleTableFields.Role]:
                      NetworkRoleTypeToLabel[
                        org_role.role as NetworkRoleType
                      ] ?? "",
                    [RoleTableFields.Id]: org_role.organization?.id || "",
                    [RoleTableFields.UserId]: org_role.user_id,
                    [RoleTableFields.roleObject]: org_role,
                  })) || []
                }
                cellWidth="small"
                listType={ListType.Orgs}
              />
            </div>

            {/* carespaces */}
            <div className="flex flex-col w-full gap-1 pt-3">
              <h2 className="text-xl">Carespace(s)</h2>
              <UserRoleTable
                listType={ListType.Carespaces}
                cellWidth="small"
                data={
                  data?.user_role.map((role) => ({
                    [RoleTableFields.WorkSpaceName]: role.network?.name ?? "",
                    [RoleTableFields.Role]:
                      NetworkRoleTypeToLabel[role.role as NetworkRoleType] ??
                      "",
                    [RoleTableFields.Id]: role.network?.id || "",
                    [RoleTableFields.UserId]: role.user_id,
                    [RoleTableFields.roleObject]: role,
                  })) ?? []
                }
              />
            </div>

            {/* only show my care link to caregivers, i.e. no org roles */}
            {!(
              data?.organization_role && data?.organization_role.length > 0
            ) ? (
              <ButtonWithIcon
                className="py-5"
                onClick={() => {
                  if (data?.id) {
                    navigate({
                      path: CarePilotRoute.MY_CARE_ID,
                      params: {
                        id: data.id,
                      },
                    });
                  }
                }}
                text={`${data?.first_name} ${data?.last_name}'s care`}
                icon={IconOption.ARROW}
              />
            ) : null}
          </>
        ) : null}
      </div>
    );
  }
}

function CarespaceSelectionForDMModal({
  isOpen,
  onClose,
  userIdToDM,
}: {
  isOpen: boolean;
  onClose: () => void;
  userIdToDM: string;
}) {
  const navigate = useAppNavigate();
  const [selectedNetworkId, setSelectedNetworkId] = useState<
    string | undefined
  >(undefined);
  const { carespacesOptionsWithoutAll } = useNetworksInOrganization();
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  const createPrivateConversation =
    useCreatePrivateConversationMutation().mutateAsync;
  return (
    <ResponsiveModal
      isOpen={isOpen}
      title={"Select Carespace"}
      closeText="Close"
      onClose={onClose}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={async () => {
              if (!selectedNetworkId || !userIdToDM) return;
              const extConversationId = await createPrivateConversation({
                network_id: selectedNetworkId,
                user_id: userIdToDM,
              });
              setActiveNetworkId(selectedNetworkId);
              navigate({
                path: CarePilotRoute.CONVERSATION,
                params: { conversation_id: extConversationId },
              });
              onClose();
            }}
            text="Send Message"
            icon={IconOption.MESSAGE}
          />
        </>
      }>
      <div className="flex flex-col gap-4">
        <p>Select the carespace you want to discuss with this provider.</p>
        <Select
          placeHolder="Select Carespace"
          currentOption={carespacesOptionsWithoutAll.find(
            (option) => option.value === selectedNetworkId
          )}
          options={carespacesOptionsWithoutAll}
          onChange={(carespaceId) => {
            setSelectedNetworkId(carespaceId);
          }}
        />
      </div>
    </ResponsiveModal>
  );
}
