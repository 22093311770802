import * as React from "react";

import { cn } from "shared/utils/cn";

import { Text } from "./text";
import { Title } from "./title";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("p-6 pt-0", className)}
    {...props}
  />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

export interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title" | "children"> {
  children: React.ReactNode;
  title?: string;
  description?: string;
  footer?: React.ReactNode;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, title, description, footer, ...rest }, ref) => {
    const hasHeader = title || description;

    return (
      <div
        ref={ref}
        className={cn(
          "rounded-lg border border-neutral-200 bg-white dark:border-neutral-800 dark:bg-neutral-950",
          className
        )}
        {...rest}>
        {hasHeader && (
          <CardHeader>
            {title && <Title order={1}>{title}</Title>}
            {description && <Text size="sm">{description}</Text>}
          </CardHeader>
        )}

        {children && <CardContent>{children}</CardContent>}
        {footer && <CardFooter>{footer}</CardFooter>}
      </div>
    );
  }
);
Card.displayName = "Card";

export { Card, CardContent, CardFooter, CardHeader };
