import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { SharedRoute, SuperSuperUserRoute, useAppNavigate } from "lib/routing";

import { LoadingSpinner } from "components/LoadingSpinner";

import { DataTable } from "shared/ui/table";
import { Title } from "shared/ui/title";
import { Text } from "shared/ui/text";
import { useOrganizationStore } from "state/organization/organization";
import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import type { Organization } from "features/organizations/types";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";

const columnHelper = createColumnHelper<Organization>();

export default function OrganizationList() {
  useRequireSuperOrg();
  const { data: organizations, isLoading } = useOrganizations({
    equals: { is_super_org: false },
  });
  const setActiveOrganizationIds = useOrganizationStore(
    (state) => state.setActiveOrganizationIds
  );
  const navigate = useAppNavigate();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        meta: {
          columnFiltering: {
            filterLabel: "Name",
          },
        },
      }),
      columnHelper.accessor("state", {
        header: "State",
        meta: {
          columnFiltering: {
            filterLabel: "State",
          },
        },
      }),
      columnHelper.accessor("city", {
        header: "City",
        meta: {
          columnFiltering: {
            filterLabel: "City",
          },
        },
      }),
      columnHelper.accessor("address", {
        header: "Address",
        meta: {
          columnFiltering: {
            filterLabel: "Address",
          },
        },
      }),
      columnHelper.accessor("zip", {
        header: "Zip",
        meta: {
          columnFiltering: {
            filterLabel: "Zip",
          },
        },
      }),
    ],
    []
  );

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6 mb-8">
      <Title>Organizations</Title>
      <div className="flex items-center mb-8">
        <ButtonWithIcon
          text="Add Organization"
          icon={IconOption.PLUS}
          onClick={() => {
            navigate({
              path: SuperSuperUserRoute.ORGANIZATIONS_NEW,
            });
          }}
        />
      </div>
      {organizations ? (
        <DataTable
          columns={columns}
          data={organizations}
          columnFiltering={{
            columnFilters,
            setColumnFilters,
          }}
          onRowClick={(row) => {
            setActiveOrganizationIds([row.id]);
            navigate({
              path: SharedRoute.ADMIN,
            });
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Text>No organizations found</Text>
        </div>
      )}
    </div>
  );
}
