import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import type { ShareableRecommendationType } from "backend/resources/shareableRecommendation/shareableRecommendation";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import type { Database } from "../../../../types/supabase";

const TABLE = "user_created_recommendation";

export type UserCreatedRecommendation =
  Database["public"]["Tables"]["user_created_recommendation"]["Row"] & {
    recommendation: ShareableRecommendationType;
  };

async function fetchUserCreatedRecommendations(
  created_by_id: string | undefined
) {
  if (!created_by_id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .select("*, recommendation(*)")
    .eq("created_by_id", created_by_id);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    (data?.map(
      (item) => item.recommendation
    ) as ShareableRecommendationType[]) ?? []
  );
}

async function addUserCreatedRecommendation(
  created_by_id: string,
  recommendation_id: string,
  organization_id: string
) {
  const { data, error } = await supabase.from(TABLE).insert([
    {
      created_by_id,
      recommendation_id,
      organization_id,
    },
  ]);

  if (error) {
    Sentry.captureException(error);
  }

  return data;
}

async function deleteUserCreatedRecommendation(recommendation_id?: string) {
  if (!recommendation_id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .eq("recommendation_id", recommendation_id);

  if (error) {
    Sentry.captureException(error);
  }

  return data;
}

export const useUserCreatedRecommendationsQuery = () => {
  const { authUser } = useAuthUser();
  return useQuery({
    queryKey: [QUERY_KEYS.userCreatedRecommendations, { userId: authUser?.id }],
    queryFn: () => fetchUserCreatedRecommendations(authUser?.id),
  });
};

export const useAddUserCreatedRecommendationMutation = () =>
  useMutation({
    mutationFn: ({
      created_by_id,
      recommendation_id,
      organization_id,
    }: {
      created_by_id: string;
      recommendation_id: string;
      organization_id: string;
    }) =>
      addUserCreatedRecommendation(
        created_by_id,
        recommendation_id,
        organization_id
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userCreatedRecommendations],
      });
    },
  });

export const useDeleteUserCreatedRecommendationMutation = () =>
  useMutation({
    mutationFn: (recommendation_id?: string) =>
      deleteUserCreatedRecommendation(recommendation_id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userCreatedRecommendations],
      });
    },
  });
