import * as Sentry from "@sentry/react";
import { z } from "zod";

import { Title } from "shared/ui/title";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneNumberSchema, ZipCodeSchema } from "shared/forms/types";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { Button } from "shared/ui/button";
import { useInsert as useInsertOrganization } from "features/organizations/queries/hooks";
import { useToast } from "shared/hooks/use-toast";
import { SuperSuperUserRoute, useAppNavigate } from "lib/routing";
import { queryClient } from "app";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";
import { queryKeys } from "../queries";

const formSchema = z.object({
  name: z.string().min(1, { message: "Organization name is required" }),
  address: z.string().min(1, { message: "Address is required" }),
  city: z.string().min(1, { message: "City is required" }),
  state: z.string().min(2, { message: "State is required" }),
  zip: ZipCodeSchema,
  helpline_phone_number: PhoneNumberSchema.optional().nullable(),
});

type FormSchema = z.infer<typeof formSchema>;

export default function NewOrganization() {
  useRequireSuperOrg();
  const insertOrg = useInsertOrganization().mutateAsync;
  const navigate = useAppNavigate();
  const { toast } = useToast();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      helpline_phone_number: null,
    },
  });

  async function onSubmit(data: FormSchema) {
    try {
      await insertOrg({
        name: data.name,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        helpline_phone_number: data.helpline_phone_number,
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.all,
      });

      toast({
        title: "Organization created",
        description: "Access the organization to add users",
      });
      navigate({
        path: SuperSuperUserRoute.ORGANIZATIONS,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Sorry, something went wrong",
        description: "Please contact support for help.",
        variant: "destructive",
      });
    }
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6 max-w-2xl">
      <Title className="mb-8">New organization</Title>

      <Form
        form={form}
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          label="Name"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
            />
          )}
        />

        <FormField
          control={form.control}
          name="address"
          label="Address"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
            />
          )}
        />

        <FormField
          control={form.control}
          name="city"
          label="City"
          render={({ field }) => (
            <Input
              {...field}
              type="text"
            />
          )}
        />

        <div className="flex gap-3 h-full">
          <FormField
            control={form.control}
            name="state"
            label="State"
            className="grow"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                maxLength={2}
              />
            )}
          />

          <FormField
            control={form.control}
            name="zip"
            label="Zip"
            className="grow"
            render={({ field }) => (
              <Input
                {...field}
                type="text"
              />
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="helpline_phone_number"
          label="Help line phone number"
          render={({ field }) => (
            <PhoneNumberInput
              disabled={field.disabled}
              cellPhone={field.value ?? undefined}
              setCellPhone={field.onChange}
            />
          )}
        />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          className="my-8">
          Save
        </Button>
      </Form>
    </div>
  );
}
