import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import OneSignalComponent from "components/OneSignalComponent/OneSignalComponent";
import ProductFruitsWrapper from "components/ProductFruitsWrapper/ProductFruitsWrapper";
import "react-loading-skeleton/dist/skeleton.css";
import { Routes } from "react-router-dom";
import type { RoutLayoutObject } from "routes";
import { renderRoute } from "routes";
import { careCentralRoutes } from "routes/care-central-routes";
import { carePilotRoutes } from "routes/carePilotRoutes";
import { sharedRoutes } from "routes/sharedRoutes";

import { AuthUserProvider } from "features/users/auth";
import queryClient from "shared/query-client";
import { Toaster } from "shared/ui/toaster";

/**
 * Renders the entry point for the app.
 */
export function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <AuthUserProvider>
          <OneSignalComponent />
          <ProductFruitsWrapper />
          <Routes>
            {[...sharedRoutes, ...carePilotRoutes, ...careCentralRoutes].map(
              (route: RoutLayoutObject) => renderRoute(route)
            )}
          </Routes>
          <Toaster />
        </AuthUserProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export { default as queryClient } from "shared/query-client";
