import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";
import { useNavigate } from "react-router-dom";

import { SharedRoute } from "lib/routing";

import { supabase } from "clients/supabaseClient";

const {
  data: { session: currentSession },
} = await supabase.auth.getSession();

export interface AuthUser {
  id: string;
  email: string;
}

export const AuthUserContext = createContext<
  | {
      authUser: AuthUser | undefined;
      setAuthUser: Dispatch<SetStateAction<AuthUser | undefined>>;
      logout: () => Promise<void>;
    }
  | undefined
>(undefined);

interface AuthUserProviderProps {
  children: ReactNode;
}

export function AuthUserProvider({ children }: AuthUserProviderProps) {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(
    currentSession
      ? {
          id: currentSession.user.id,
          email: currentSession.user.email || "",
        }
      : undefined
  );

  const logout = async () => {
    await supabase.auth.signOut();
    setAuthUser(undefined);
    navigate(SharedRoute.LOGIN);
  };

  return (
    <AuthUserContext.Provider
      value={{
        authUser,
        setAuthUser,
        logout,
      }}>
      {children}
    </AuthUserContext.Provider>
  );
}

export function useAuthUser() {
  const value = useContext(AuthUserContext);

  if (!value) {
    throw new Error("useAuthUser must be used within an AuthUserContext");
  }

  return value;
}
