import { useQueryLibraryFolders } from "backend/resources/libraryFolder";
import {
  useAddBookmarkFromFolderMutation,
  useLibraryFoldersForUserBookmarkId,
} from "backend/resources/userBookmarkToLibraryFolder";
import { supabase } from "clients/supabaseClient";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { useAuthUser } from "features/users/auth";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";

interface Props {
  close: () => void;
  userBookmarkId: string;
}
export default function AddToFolderPopup({ close, userBookmarkId }: Props) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <AddToFolderPopupComponent
          close={close}
          userBookmarkId={userBookmarkId}
        />
      </BottomSheet>
    );
  } else {
    return (
      <Popup
        containerClassName="min-w-[300px]  max-h-[81vh] overflow-y-scroll relative"
        close={close}>
        <AddToFolderPopupComponent
          close={close}
          userBookmarkId={userBookmarkId}
        />
      </Popup>
    );
  }
}

function AddToFolderPopupComponent({ close, userBookmarkId }: Props) {
  const { authUser } = useAuthUser();
  const navigate = useAppNavigate();
  const [error, setError] = useState<string>("");

  const { libraryFolders } = useQueryLibraryFolders();

  const {
    data: libraryFoldersForBookmark,
    refetch: refetchLibraryFoldersForBookmark,
  } = useLibraryFoldersForUserBookmarkId(userBookmarkId, authUser?.id);

  async function removeBookmarkFromFolder(folderId: string) {
    if (authUser) {
      const { data } = await supabase
        .from("user_bookmark_to_library_folder")
        .delete()
        .eq("library_folder_id", folderId)
        .eq("user_bookmark_id", userBookmarkId)
        .eq("user_id", authUser?.id)
        .select();

      if (data && data.length > 0) {
        refetchLibraryFoldersForBookmark();
      } else {
        setError("Something went wrong.");
      }
    }
  }

  const addBookmarkToFolder = useAddBookmarkFromFolderMutation().mutateAsync;

  const hasFolders = libraryFolders && libraryFolders.length > 0;
  return (
    <div className="mx-10 pb-20 md:pb-0 md:mx-0 flex flex-col gap-4 relative">
      <h1 className="text-lg">Add to Folder</h1>
      {!hasFolders ? (
        <p className="font-light">
          You must first add a folder. Go to the library page to add a folder{" "}
        </p>
      ) : null}
      <div className="w-full px-5 flex flex-col gap-3">
        {libraryFolders?.map((folder) => (
          <div
            key={folder.id}
            className="flex gap-3">
            <DefaultCheckbox
              checked={
                !!libraryFoldersForBookmark?.find(
                  (libraryFolderForBookmark) =>
                    libraryFolderForBookmark.library_folder_id === folder.id
                )
              }
              onChange={(isChecked: boolean) => {
                if (isChecked) {
                  addBookmarkToFolder({ folderId: folder.id, userBookmarkId });
                } else {
                  removeBookmarkFromFolder(folder.id);
                }
              }}
            />
            <p>{folder.name}</p>
          </div>
        ))}
      </div>
      <FooterButtons>
        {hasFolders ? (
          <ButtonWithIcon
            text="Ok"
            onClick={close}
            icon={IconOption.CHECKMARK}
          />
        ) : (
          <>
            <ButtonWithIcon
              text="Cancel"
              size={"small"}
              onClick={close}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              text="Go to Library"
              size={"small"}
              onClick={() =>
                navigate({
                  path: CarePilotRoute.FAVORITES,
                })
              }
              icon={IconOption.ARROW}
            />
          </>
        )}
      </FooterButtons>
      {error && (
        <p className="text-red-500 self-center text-sm text-center">{error}</p>
      )}
    </div>
  );
}
