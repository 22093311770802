import { zodResolver } from "@hookform/resolvers/zod";
import { useOrgs } from "backend/resources/orgRole";
import { OrgRoleType } from "backend/resources/userRole/types";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { OrgMemberCombobox } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { Form } from "shared/ui/form";
import { useActiveOrganizationIds } from "state/organization/organization";
import { z } from "zod";

export const assignCareTeamSchema = z.object({
  orgMember: z
    .object({
      organization_id: z.string(),
      org_role: z.string(),
      user_id: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      cell_number: z.string(),
      email: z.string(),
    })
    .optional(),
});

export const useAssignCareTeamForm = () =>
  useForm<z.infer<typeof assignCareTeamSchema>>({
    resolver: zodResolver(assignCareTeamSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

interface AssignCareNavigatorFormProps {
  form: UseFormReturn<z.infer<typeof assignCareTeamSchema>>;
}

export function AssignCareNavigatorForm({
  form,
}: AssignCareNavigatorFormProps) {
  const organizationIds = useActiveOrganizationIds();
  const { isSuperSuperUser } = useOrgs();

  if (!organizationIds) return null;

  if (organizationIds.length > 1) {
    throw new Error("Multiple organizations are not supported");
  }

  return (
    <Form
      form={form}
      id="assign-care-navigator-form">
      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-col w-full gap-3">
          <p>Care Navigator</p>
          <OrgMemberCombobox
            form={form}
            orgId={organizationIds[0]}
            role_type={OrgRoleType.CARE_NAVIGATOR}
            isSuperSuperUser={isSuperSuperUser}
          />
        </div>
      </div>
    </Form>
  );
}
