import { zodResolver } from "@hookform/resolvers/zod";
import {
  OrgRoleType,
  OrgRoleTypeToLabel,
} from "backend/resources/userRole/types";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { OrgMemberCombobox } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { Form } from "shared/ui/form";
import { Title } from "shared/ui/title";
import { useActiveOrganizationIds } from "state/organization/organization";
import * as z from "zod";

export const orgMemberInnerSchema = z.object({
  organization_id: z.string(),
  org_role: z.string(),
  user_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  cell_number: z.string(),
  email: z.string(),
});

export const orgMemberSchema = z.object({
  orgMember: orgMemberInnerSchema.optional(),
  isUsingCraniometrix: z.boolean().optional(),
});

export const useAddOrgMemberForm = () =>
  useForm<z.infer<typeof orgMemberSchema>>({
    resolver: zodResolver(orgMemberSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

export type AddOrgMemberFormHook = UseFormReturn<
  z.infer<typeof orgMemberSchema>
>;

export const addOrgMemberFormId = "add-org-member-form";

export function AddOrgMemberNavigatorForm({
  form,
  roleType,
  step,
  isUsingCmCareNavigators,
}: {
  form: UseFormReturn<z.infer<typeof orgMemberSchema>>;
  roleType: OrgRoleType | undefined;
  step?: number;
  isUsingCmCareNavigators?: boolean;
}) {
  const organizationIds = useActiveOrganizationIds();

  if (isUsingCmCareNavigators && roleType === OrgRoleType.CARE_NAVIGATOR) {
    return null;
  }

  if (!organizationIds) {
    return null;
  }

  if (organizationIds.length > 1) {
    throw new Error("Multiple organizations are not supported");
  }

  return (
    <Form
      form={form}
      id={`${addOrgMemberFormId}-${roleType}`}>
      <Title order={3}>
        {step && `Step ${step}:`} Add a{" "}
        {roleType ? OrgRoleTypeToLabel[roleType] : "Org Member"}
      </Title>
      <div className="flex flex-col items-center md:items-start gap-4">
        <div className="grid grid-cols-[2fr,3fr] items-center gap-3 w-full">
          <p>Member Name</p>
          <OrgMemberCombobox
            key={roleType}
            form={form}
            orgId={organizationIds[0]}
            role_type={roleType}
          />
        </div>
      </div>
      <p className="w-full font-light text-sm">
        Note: if the member is not found, please add them to the organization
        and then you can add them to the Carespace.
      </p>
    </Form>
  );
}
