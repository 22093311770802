import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import queryClient from "shared/query-client";

import {
  buildFilters,
  type Filter,
  type QueryOptions,
} from "features/query-utils";

import type {
  DatabaseUser,
  TableName,
  User,
  UserInsert,
  UserUpdate,
} from "../types";

import { fields, insert, queryKeys, select, update } from ".";

function fromDatabase(databaseUser: DatabaseUser): User {
  return {
    ...databaseUser,
    organizations: databaseUser.organization_role.map(
      (role) => role.organization_id
    ),
  };
}

export function useFetchOne(
  filter: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return fromDatabase(data);
    },
    enabled,
  });
}

export function useUpdate(filter: Filter<TableName>) {
  return useMutation({
    mutationFn: async (userUpdate: UserUpdate) => {
      const mutation = buildFilters(update(userUpdate), filter);

      const { data, error } = await mutation.select(fields).maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      if (!data) {
        throw new Error(`No data found for user after update:
          filter: ${JSON.stringify(filter, null, 2)}

          userUpdate: ${JSON.stringify(userUpdate, null, 2)}
        `);
      }

      return fromDatabase(data);
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(filter),
      }),
  });
}

export function useInsert() {
  return useMutation({
    mutationFn: async (userInsert: UserInsert) => {
      const { data, error } = await insert(userInsert);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data.map((user) => fromDatabase(user));
    },
  });
}
