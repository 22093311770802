import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { supabase } from "clients/supabaseClient";
import { subHours } from "date-fns";
import { useAuthUser } from "features/users/auth";
import { useEffect, useState } from "react";
import { useActiveNetworkId } from "state/network/network";
import type { Database } from "../../../../types/supabase";

const TABLE = "care_plan_generation_status";

export enum CarePlanGenerationStatuses {
  ERROR = "error",
  PENDING = "pending",
  COMPLETE = "complete",
}

export type CarePlanGenerationStatus =
  Database["public"]["Tables"]["care_plan_generation_status"]["Row"];

export const useCarePlanGenerationStatus = () => {
  const { authUser } = useAuthUser();
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const activeNetworkId = useActiveNetworkId();

  const queryResult = useQuery({
    queryKey: [
      QUERY_KEYS.carePlanGenerationStatus,
      { userId: authUser?.id, activeNetworkId },
    ],
    queryFn: async () => {
      if (!activeNetworkId) return null;

      const { data, error } = await supabase
        .from(TABLE)
        .select()
        .eq("network_id", activeNetworkId)
        .order("created_at", { ascending: false })
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      const oneHourAgo = subHours(new Date(), 1);
      // If it's been loading for > 1 hour, mark it as error
      if (
        data?.status === CarePlanGenerationStatuses.PENDING &&
        new Date(data.created_at) < oneHourAgo
      ) {
        const { error: updateError } = await supabase
          .from(TABLE)
          .update({ status: CarePlanGenerationStatuses.ERROR.toString() })
          .eq("id", data.id);

        if (updateError) {
          Sentry.captureException(updateError);
          throw new Error(updateError.message);
        }

        data.status = CarePlanGenerationStatuses.ERROR;
      }

      if (
        data?.status === CarePlanGenerationStatuses.COMPLETE ||
        data?.status === CarePlanGenerationStatuses.ERROR
      ) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.goal],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.carePlan],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.carePlanGoal],
        });
      }
      return data;
    },
    refetchInterval: 2000,
    enabled: currentStatus !== CarePlanGenerationStatuses.COMPLETE,
  });

  useEffect(() => {
    if (queryResult.data?.status !== currentStatus) {
      setCurrentStatus(queryResult.data?.status || null);
    }
  }, [queryResult.data?.status, currentStatus]);

  return queryResult;
};
