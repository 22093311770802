export const TABLE_NAME = "user";

export enum OrganizationRole {
  ADMIN = "admin",
  DOCTOR = "doctor",
  CARE_NAVIGATOR = "professional caregiver",
}

export enum NetworkRole {
  DOCTOR = OrganizationRole.DOCTOR,
  CARE_NAVIGATOR = OrganizationRole.CARE_NAVIGATOR,
  ADMIN = OrganizationRole.ADMIN,
  PRIMARY_CAREGIVER = "primary caregiver",
  CAREGIVER = "caregiver",
  FAMILY_MEMBER = "family member",
}

export const RoleLabel: Record<NetworkRole, string> = {
  [NetworkRole.DOCTOR]: "Doctor/Nurse",
  [NetworkRole.CARE_NAVIGATOR]: "Care Navigator",
  [NetworkRole.ADMIN]: "Administrator",
  [NetworkRole.PRIMARY_CAREGIVER]: "Primary Caregiver",
  [NetworkRole.CAREGIVER]: "Caregiver",
  [NetworkRole.FAMILY_MEMBER]: "Family Member",
};
