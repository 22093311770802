import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";

import { DataTable } from "shared/ui/table";
import { Title } from "shared/ui/title";
import { Text } from "shared/ui/text";
import type { OrganizationInvitationWithOrgRole } from "backend/resources/organizationInvitations/organizationInvitation";
import { useOrganizationInvitationQuery } from "backend/resources/organizationInvitations/organizationInvitation";
import { SuperSuperUserRoute, useAppNavigate } from "lib/routing";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";

const columnHelper = createColumnHelper<OrganizationInvitationWithOrgRole>();

export default function MemberList() {
  useRequireSuperOrg();
  const { organizationInvitations, isLoadingOrganizationInvitations } =
    useOrganizationInvitationQuery();
  const navigate = useAppNavigate();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        header: "First Name",
        meta: {
          columnFiltering: {
            filterLabel: "First Name",
          },
        },
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        meta: {
          columnFiltering: {
            filterLabel: "Last Name",
          },
        },
      }),
      columnHelper.accessor("invited_email", {
        header: "E-mail",
        meta: {
          columnFiltering: {
            filterLabel: "E-mail",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        meta: {
          columnFiltering: {
            filterLabel: "Status",
          },
        },
      }),
    ],
    []
  );

  if (isLoadingOrganizationInvitations) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6">
      <Title>Super Organization Members</Title>
      <div className="flex items-center mb-8">
        <ButtonWithIcon
          text="Add Member"
          icon={IconOption.PLUS}
          onClick={() => {
            navigate({
              path: SuperSuperUserRoute.MEMBERS_NEW,
            });
          }}
        />
      </div>
      {organizationInvitations ? (
        <DataTable
          columns={columns}
          data={organizationInvitations}
          columnFiltering={{
            columnFilters,
            setColumnFilters,
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Text>No members found</Text>
        </div>
      )}
    </div>
  );
}
