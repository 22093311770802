import type { UserAdloWithOrgAndDoctor } from "backend/resources/userAdlo";

import { flexRender } from "@tanstack/react-table";
import { Cell, Row } from "@tanstack/table-core";
import { useEffect, useState } from "react";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useNetworkStore } from "state/network/network";
import { useOrganizationStore } from "state/organization/organization";
import type { TableDataRowType } from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";

export enum PatientFields {
  Name = "Name",
  DOB = "DOB",
  Organization = "Organization",
  Doctor = "Doctor",
  OrganizationId = "OrganizationId",
  NetworkId = "NetworkId",
}

type BasePatientType = {
  [PatientFields.Name]: string;
  [PatientFields.DOB]: string;
  [PatientFields.Organization]: string;
  [PatientFields.Doctor]: string;
  [PatientFields.OrganizationId]: string;
  [PatientFields.NetworkId]: string;
};
export type PatientType = BasePatientType & TableDataRowType<BasePatientType>;

export default function PatientSearchResultsTable({
  data: rawData,
}: {
  data: UserAdloWithOrgAndDoctor[] | null | undefined;
}) {
  const navigate = useAppNavigate();
  const [data, setData] = useState<PatientType[]>(() => []);

  const setActiveOrganizationIds = useOrganizationStore(
    (state) => state.setActiveOrganizationIds
  );
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  useEffect(() => {
    if (rawData) {
      const data = rawData.map((item) => ({
        [PatientFields.Name]: `${item.first_name} ${item.last_name}`,
        [PatientFields.DOB]: item.birthday
          ? new Date(item.birthday).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })
          : "",
        [PatientFields.Organization]: item.organization.name,
        [PatientFields.Doctor]: item.doctor_name,
        [PatientFields.OrganizationId]: item.organization.id,
        [PatientFields.NetworkId]: item.network_id,
      }));
      setData(data);
    }
  }, [rawData]);

  return (
    <InfiniteScrollingTable
      data={data}
      hiddenColumns={[PatientFields.OrganizationId, PatientFields.NetworkId]}
      columnFields={Object.values(PatientFields)}
      handleClick={(cell) => {
        setActiveOrganizationIds([
          cell.row.original[PatientFields.OrganizationId],
        ]);
        setActiveNetworkId(cell.row.original[PatientFields.NetworkId]);
        navigate({
          path: CareCentralRoute.CARESPACE,
          params: {
            carespaceId: cell.row.original[PatientFields.NetworkId],
          },
        });
      }}
      CellContent={({ cell, row }) => (
        <CellContent
          cell={cell}
          row={row}
        />
      )}
      emptyText="No patients found"
    />
  );
}

function CellContent({
  cell,
}: {
  cell: Cell<PatientType, unknown>;
  row: Row<PatientType>;
}) {
  return (
    <p className={`truncate text-left max-w-[200px] w-[200px]`}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </p>
  );
}
