import { useOrgs } from "backend/resources/orgRole";
import AlfredIcon from "components/Alfred/Alfred";
import { useAuthUser } from "features/users/auth";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { useGptStore } from "state/gpt";

export function FloatingAlfredButton() {
  const { authUser } = useAuthUser();
  const { isMobile } = useWindowSize();
  const isOpen = useGptStore((state) => state.isOpen);
  const setIsOpen = useGptStore((state) => state.setIsOpen);
  const location = useLocation();
  const { data: productAccess } = useProductAccess();
  const fullUrl = location.pathname + location.search;
  const { isSuperSuperUser } = useOrgs();

  return (
    <>
      {isMobile &&
      authUser != null &&
      (productAccess === CraniometrixProduct.CARE_PILOT || isSuperSuperUser) &&
      fullUrl !== "/carepilot/care-team?tab=Discussion" ? (
        <div className="w-full absolute bottom-20 pointer-events-none">
          <div className="mx-auto w-[80%] flex justify-end pointer-events-none">
            <button
              className={`pointer-events-auto hover:bg-gray-100 flex items-center justify-center w-12 h-12 rounded-full border border-brand-orange bg-white z-[12]`}
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              <AlfredIcon className=" rounded-full flex-shrink-0 w-8 h-8" />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}
