import { useOrgs } from "backend/resources/orgRole";
import { useUsersInNetwork } from "backend/resources/userRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { useAuthUser } from "features/users/auth";
import { useMemo } from "react";

export function useRole() {
  const { isSuperSuperUser, hasAdminAccess, ownOrgIdentities, isLoading } =
    useOrgs();
  const { authUser } = useAuthUser();
  const { data: userRoles } = useUsersInNetwork();
  const userRole = userRoles?.find(
    (userWithRole) => userWithRole.user?.id === authUser?.id
  );

  // Memoize the role to prevent unnecessary updates
  const role = useMemo(() => {
    // Super Super User and Admin cases both map to ADMIN
    if (isSuperSuperUser || hasAdminAccess) return NetworkRoleType.ADMIN;
    // Check for org roles
    if (ownOrgIdentities?.length)
      return ownOrgIdentities[0].role as NetworkRoleType;
    // CarePilot user (will have userRole but no org roles)
    if (userRole?.role) return userRole.role;
    // Default undefined if no role found
    return undefined;
  }, [isSuperSuperUser, hasAdminAccess, ownOrgIdentities, userRole]);

  return {
    role,
    isLoading,
    isSuperSuperUser,
    hasAdminAccess,
  };
}
