import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import queryClient from "shared/query-client";

import {
  buildFilters,
  type Filter,
  type QueryOptions,
} from "features/query-utils";

import { queryKeys as userQueryKeys } from "features/users/queries";

import type {
  OrganizationInsert,
  OrganizationUpdate,
  TableName,
} from "../types";

import {
  assignUserToOrg,
  fields,
  insert,
  inviteUserToOrg,
  queryKeys,
  select,
  update,
} from ".";

export function useFetchOne(
  filter: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useFetchMany(
  filter: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query;

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useUpdate(filter: Filter<TableName>) {
  return useMutation({
    mutationFn: async (organizationUpdate: OrganizationUpdate) => {
      const mutation = buildFilters(update(organizationUpdate), filter);

      const { data, error } = await mutation.select(fields).maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      if (!data) {
        throw new Error(`No data found for organization after update:
          filter: ${JSON.stringify(filter, null, 2)}

          organizationUpdate: ${JSON.stringify(organizationUpdate, null, 2)}
        `);
      }

      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(filter),
      }),
  });
}

export function useInsert() {
  return useMutation({
    mutationFn: async (organizationInsert: OrganizationInsert) => {
      const { data, error } = await insert(organizationInsert);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
  });
}

export function useAssignUserToOrg() {
  return useMutation({
    mutationFn: async (params: Parameters<typeof assignUserToOrg>[0]) => {
      const { data, error } = await assignUserToOrg(params);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      if (!data) {
        throw new Error(
          `No data returned when assigning user(${params.userId}) to organization(${params.organizationId})`
        );
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail({ equals: { id: data.organization_id } }),
      });

      queryClient.invalidateQueries({
        queryKey: userQueryKeys.detail({ equals: { id: data.user_id } }),
      });
    },
  });
}

export function useInviteUserToOrg() {
  return useMutation({
    mutationFn: async (params: Parameters<typeof inviteUserToOrg>[0]) => {
      const { data, error } = await inviteUserToOrg(params);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      if (!data) {
        throw new Error(
          `No data returned when inviting user(${params.email}) to organization(${params.organization_id})`
        );
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail({ equals: { id: data.organization_id } }),
      });

      queryClient.invalidateQueries({
        queryKey: userQueryKeys.detail({ equals: { id: data.id } }),
      });
    },
  });
}
