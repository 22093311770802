import { useRole } from "hooks/role/useRole";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useEffect } from "react";
import { useActiveOrganizationIds } from "state/organization/organization";

export default function useRequireSuperOrg() {
  const activeOrganizationIds = useActiveOrganizationIds();
  const { isSuperSuperUser } = useRole();
  const navigate = useAppNavigate();

  const isAllOrganizationsSelected =
    activeOrganizationIds && activeOrganizationIds.length > 1;

  useEffect(() => {
    if (!isSuperSuperUser || !isAllOrganizationsSelected) {
      navigate({
        path: SharedRoute.ROOT,
      });
    }
  }, [navigate, isAllOrganizationsSelected, isSuperSuperUser]);
}
