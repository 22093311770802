import commandScore from "command-score";
import { Check, ChevronDown } from "lucide-react";
import * as React from "react";

import { Button } from "shared/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "shared/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "shared/ui/popover";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";

import { cn } from "shared/utils/cn";

export interface ComboboxProps extends ControlledProps<string> {
  options: {
    value: string;
    label?: string;
    icon?: React.ReactNode;
  }[];
  className?: string;
  id?: string;
  searchable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  preventUnselect?: boolean;
  emptyMessage?: string;
}

const Combobox = React.forwardRef<
  React.ElementRef<typeof Button>,
  ComboboxProps
>(
  (
    {
      options,
      className,
      id,
      searchable,
      disabled,
      placeholder,
      preventUnselect,
      emptyMessage = "No results",
      ...props
    },
    ref
  ) => {
    const [search, setSearch] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const { value, onChange } = useControlledProps(props);
    const selectedValue = React.useMemo(
      () => options.find((option) => option.value === value),
      [value, options]
    );
    const defaultPlaceholder = searchable ? "Search..." : "Select...";

    return (
      <Popover
        open={open}
        onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="combobox"
            role="combobox"
            disabled={disabled}
            aria-expanded={open}
            className={className}>
            {selectedValue ? (
              <div className="flex gap-2 truncate">
                {selectedValue?.icon}{" "}
                <span className="truncate">
                  {selectedValue.label || selectedValue.value}
                </span>
              </div>
            ) : (
              placeholder || defaultPlaceholder
            )}

            <ChevronDown
              className={cn(
                "h-4 w-4 text-brand-orange transition-transform",
                open ? "rotate-180" : "rotate-0"
              )}
            />
          </Button>
        </PopoverTrigger>

        <PopoverContent
          className="p-2"
          sideOffset={6}
          align="start">
          <Command
            filter={(value, search) => {
              const option = options.find((option) => option.value === value);

              if (!option) {
                return 0;
              }

              const compareValue = option.label || value;

              return commandScore(compareValue, search);
            }}>
            {searchable && (
              <CommandInput
                value={search}
                onValueChange={setSearch}
                placeholder={placeholder || defaultPlaceholder}
              />
            )}
            <CommandList>
              <CommandEmpty>{emptyMessage}</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={(newValue) => {
                      onChange(
                        newValue === value
                          ? preventUnselect
                            ? newValue
                            : undefined
                          : newValue
                      );
                      setOpen(false);
                    }}>
                    {option.icon}
                    {option.label || option.value}
                    <Check
                      className={cn(
                        "ml-auto w-4 h-4 text-brand-orange",
                        value === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);
Combobox.displayName = "Combobox";

export { Combobox };
