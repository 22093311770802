import type { Cell, Row } from "@tanstack/react-table";
import type {
  NetworkWithPlanEntries,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";
import { TaskStatus } from "backend/resources/planEntry";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { useAuthUser } from "features/users/auth";
import { CareCentralRoute, SharedRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { getTaskType } from "../TaskTable/TaskTableTypes";
import TasksByCarespaceTableCellContent, {
  TaskTableCellContentSubrow,
} from "./TaskTableCellContent";
import type {
  TasksByCarespaceTableSubRowType,
  TasksByCarespaceTableType,
} from "./TaskTableTypes";
import {
  TasksByCarespaceTableFields,
  TasksByCarespaceTableSubRowFields,
} from "./TaskTableTypes";

export function TasksByCarespaceTable({
  rawData,
}: {
  rawData: NetworkWithPlanEntries[];
}) {
  const [data, setData] = useState<TasksByCarespaceTableType[]>(() => []);
  const { authUser } = useAuthUser();
  const navigate = useAppNavigate();

  useEffect(() => {
    const formattedData = rawData.map((item) => {
      const status = item.plan_entry.every(
        (entry) =>
          entry.status === TaskStatus.Done ||
          entry.status === TaskStatus.NotApplicable
      )
        ? TaskStatus.Done
        : item.plan_entry.every(
            (entry) => entry.status === TaskStatus.NotStarted
          )
        ? TaskStatus.NotStarted
        : TaskStatus.InProgress;

      const nextDueDate: Date | null =
        item.plan_entry.length === 0
          ? null
          : item.plan_entry.reduce((earliest, entry) => {
              const entryDate = new Date(entry.scheduled_date_time ?? "");
              if (isNaN(entryDate.getTime())) {
                return earliest; // Skip invalid dates
              }
              return !earliest || entryDate < earliest ? entryDate : earliest;
            }, null as Date | null);

      return {
        [TasksByCarespaceTableFields.CARESPACE_NAME]: item.name ?? "",
        [TasksByCarespaceTableFields.Id]: item.id,
        [TasksByCarespaceTableFields.Type]: "",
        [TasksByCarespaceTableFields.Status]: status,
        [TasksByCarespaceTableFields.Due_Date]: nextDueDate
          ? nextDueDate.toLocaleDateString("en-US")
          : "",
        [TasksByCarespaceTableFields.TIME]: nextDueDate
          ? nextDueDate.toLocaleTimeString("en-US")
          : "",
        [TasksByCarespaceTableFields.GUIDE_Percentage_Complete]: 0,
        [TasksByCarespaceTableFields.RawData]: item,
        subRows: item.plan_entry
          .sort((a, b) =>
            (a.mah_need_item?.title ?? "").localeCompare(
              b.mah_need_item?.title ?? ""
            )
          )
          .map((entry) => ({
            [TasksByCarespaceTableSubRowFields.Id]: entry.id,
            [TasksByCarespaceTableSubRowFields.TASK_NAME]: entry.name,
            [TasksByCarespaceTableSubRowFields.Type]:
              entry.type ?? getTaskType(entry),
            [TasksByCarespaceTableSubRowFields.Status]:
              entry.status as TaskStatus,
            [TasksByCarespaceTableSubRowFields.Due_Date]:
              entry.scheduled_date_time
                ? new Date(entry.scheduled_date_time).toLocaleDateString(
                    "en-US"
                  )
                : "",
            [TasksByCarespaceTableSubRowFields.TIME]: entry.scheduled_date_time
              ? new Date(entry.scheduled_date_time).toLocaleTimeString("en-US")
              : "",
            [TasksByCarespaceTableSubRowFields.Who]:
              entry.plan_entry_attendee?.find(
                (attendee) => attendee.user_id === authUser?.id
              )?.user_id ?? entry.user_id,
            [TasksByCarespaceTableSubRowFields.RawData]: entry,
            subRows: [],
          })),
      };
    });
    setData(formattedData);
  }, [rawData]);

  function handleClick(
    cell: Cell<TasksByCarespaceTableSubRowType, unknown>,
    row: Row<TasksByCarespaceTableSubRowType>
  ) {
    navigate({
      path: (
        row.original[
          TasksByCarespaceTableSubRowFields.RawData
        ] as TaskWithGuideInfo
      ).is_service_ticket
        ? SharedRoute.SERVICE_REQUEST_VIEW
        : CareCentralRoute.VIEW_TASK,
      params: {
        id: row.original.Id,
      },
    });
  }

  return (
    <InfiniteScrollingTable<
      TasksByCarespaceTableType,
      TasksByCarespaceTableSubRowType
    >
      data={data}
      columnFields={Object.values(TasksByCarespaceTableFields)}
      handleClick={(
        cell: Cell<TasksByCarespaceTableType, unknown>,
        row: Row<TasksByCarespaceTableType>
      ) => {
        row.toggleExpanded();
      }}
      hiddenColumnHeaders={[TasksByCarespaceTableFields.Type]}
      CellContent={TasksByCarespaceTableCellContent}
      headersToCenter={[TasksByCarespaceTableFields.Status]}
      maxHeight="85vh"
      hiddenColumns={[
        TasksByCarespaceTableFields.RawData,
        TasksByCarespaceTableFields.Id,
      ]}
      subrowOptions={{
        headersToCenter: [TasksByCarespaceTableSubRowFields.Status],
        hiddenColumns: [
          TasksByCarespaceTableSubRowFields.RawData,
          TasksByCarespaceTableSubRowFields.Id,
        ],
        columnFields: Object.values(TasksByCarespaceTableSubRowFields),
        handleClick,
        CellContent: TaskTableCellContentSubrow,
      }}
    />
  );
}
