import { SplideSlide } from "@splidejs/react-splide";
import {
  useCarePlanHighlightByInterventionId,
  useCarePlanHighlights,
} from "backend/resources/carePlanHighlights/carePlanHighlights";
import {
  UserBookmarkType,
  useBookmarkQuery,
  useBookmarkQueryByBookmarkableItem,
} from "backend/resources/userBookmark";
import BookmarkableCard from "components/Card/BookmarkableCard";
import { Card } from "components/Card/Card";
import Carousel from "components/Carousel/Carousel";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import { useAssessmentStore } from "state/assessment";
import { useGptStore } from "state/gpt";

export default function CarePlanHighlights() {
  // Store

  // Queries
  const { data: carePlanHightlights } = useCarePlanHighlights();

  if (!carePlanHightlights) {
    return null;
  }

  // Render recommendations
  return (
    <div className="flex flex-col gap-5">
      <h1 className="text-[22px] font-light">Coaching Plan Highlights</h1>
      <div>
        <Carousel
          items={carePlanHightlights.map((pick) => (
            <SplideSlide key={pick.intervention_id}>
              <CarePlanHighlightCard pick={pick} />
            </SplideSlide>
          ))}
        />
      </div>
    </div>
  );
}

function CarePlanHighlightCard({
  pick,
}: {
  pick: { intervention_id: string | null; is_canonical?: boolean };
}) {
  const navigate = useAppNavigate();
  const setActiveUserAssessmentId = useAssessmentStore(
    (state) => state.setActiveUserAssessmentId
  );

  // GPT Stores:
  const setUserAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.setUserAssessmentChatGptRecommendationId
  );
  const setUserAssessmentChatGptInterventionId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionId
  );

  const isCanonicalPick = (
    pick: { intervention_id: string | null; is_canonical?: boolean } | undefined
  ) => pick?.is_canonical === true;

  function handleOnClick(pick: {
    intervention_id: string | null;
    is_canonical?: boolean;
  }) {
    navigate({
      path: isCanonicalPick(pick)
        ? CareCentralRoute.SHAREABLE_INTERVENTION
        : CarePilotRoute.INTERVENTION,
      params: { id: `${pick.intervention_id}` },
    });
  }

  const setSidebarType = useGptStore((state) => state.setType);

  const { refetchUserBookmark } = useBookmarkQueryByBookmarkableItem({
    itemId: pick.intervention_id || "",
    type: UserBookmarkType.INTERVENTION,
  });

  const { refetchBookmarks } = useBookmarkQuery();

  const { data: details, isLoading } = useCarePlanHighlightByInterventionId(
    pick.intervention_id
  );

  if (isCanonicalPick(pick)) {
    return (
      <Card color={"aqua"}>
        <button
          className="flex flex-col justify-around h-full w-full text-left"
          key={pick.intervention_id}
          onClick={() => handleOnClick(pick)}>
          <span className="text-lg line-clamp-2">
            {details?.user_recommendation?.title}
          </span>
          <span className="line-clamp-1 text-sm">{details?.title}</span>
        </button>
      </Card>
    );
  } else {
    return (
      <BookmarkableCard
        bookmarkableItem={{
          itemId: pick.intervention_id || "",
          type: UserBookmarkType.INTERVENTION,
        }}
        refetchBookmarks={() => {
          refetchUserBookmark();
          refetchBookmarks();
        }}>
        <button
          className="flex flex-col justify-center h-full w-full text-left px-6 py-4 md:py-3"
          key={pick.intervention_id}
          onClick={() => handleOnClick(pick)}>
          <span className="text-lg line-clamp-2">
            {details?.user_recommendation?.title}
          </span>
          <span className="line-clamp-1 text-sm">{details?.title}</span>
        </button>
      </BookmarkableCard>
    );
  }
}
