import { Combobox } from "shared/ui/combobox";
import {
  useActiveOrganizationIds,
  useOrganizationStore,
} from "state/organization/organization";
import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import type { Organization } from "features/organizations/types";
import { useRole } from "hooks/role/useRole";
import { SuperSuperUserRoute, useAppNavigate } from "lib/routing";

type OrganizationWithName = Organization & { name: string };

function sortOrganizations(a: OrganizationWithName, b: OrganizationWithName) {
  return a.name > b.name ? 1 : -1;
}

const ALL_ORGANIZATIONS = "all";

export function OrganizationSwitcher() {
  const navigate = useAppNavigate();
  const { isSuperSuperUser } = useRole();
  const activeOrganizationIds = useActiveOrganizationIds();
  const { data: organizations } = useOrganizations({
    equals: { is_super_org: false },
  });
  const setActiveOrganizationIds = useOrganizationStore(
    (state) => state.setActiveOrganizationIds
  );

  if (!organizations) {
    return null;
  }

  const filteredOrganizations = organizations
    .filter((organization): organization is OrganizationWithName =>
      Boolean(organization.name)
    )
    .sort(sortOrganizations)
    .map((organization) => ({
      value: organization.id,
      label: organization.name,
    }));

  const allOption = {
    value: ALL_ORGANIZATIONS,
    label: "All organizations",
  };

  const dropdownOptions = isSuperSuperUser
    ? [allOption, ...filteredOrganizations]
    : filteredOrganizations;

  if (!activeOrganizationIds || dropdownOptions.length < 2) {
    return null;
  }

  const currentValue =
    activeOrganizationIds.length > 1
      ? ALL_ORGANIZATIONS
      : activeOrganizationIds[0];

  return (
    <Combobox
      options={dropdownOptions}
      value={currentValue}
      onChange={(value = ALL_ORGANIZATIONS) => {
        if (value === ALL_ORGANIZATIONS) {
          setActiveOrganizationIds(organizations.map((org) => org.id));
          navigate({
            path: SuperSuperUserRoute.ORGANIZATIONS,
          });
        } else {
          setActiveOrganizationIds([value]);
        }
      }}
      emptyMessage="No organizations found"
      searchable
    />
  );
}
