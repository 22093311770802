import * as Sentry from "@sentry/react";
import type { Session } from "@supabase/supabase-js";
import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { downloadBlob } from "backend/functions/util";
import { QUERY_KEYS } from "backend/query-keys";
import type { DiscussionMessage } from "backend/resources/chatGptMessage/chatGptMessage";
import type { UserAdlo } from "backend/resources/userAdlo";
import type {
  NewsMetadata,
  UserAssessmentChatGptInterventionResource,
} from "backend/resources/userGuidanceResource";
import type { OrgRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import type { BillingPageData } from "components/BillingPage/types";
import { useAuthUser } from "features/users/auth";
import qs from "qs";
import type { YellowPagesListingSchema } from "shared/forms/types";
import { useAnalyticsPageFilterStore } from "state/analyticsPageFilter/analyticsPageFilter";
import { useActiveOrganizationIds } from "state/organization/organization";
import type { z } from "zod";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export async function getSupabaseAccessToken(session?: Session | null) {
  if (!session) {
    session = (await supabase.auth.getSession()).data.session;
  }
  const accessToken = session?.access_token;
  return accessToken;
}

export async function fetchNews() {
  const response = await fetch(`${BACKEND_URL}/news`, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
  });
  const responseBody = await response.json();

  return (
    responseBody.data as UserAssessmentChatGptInterventionResource[]
  ).filter(
    (newsResource) =>
      (newsResource.guidance_resource?.metadata as NewsMetadata).provider[0]
        .image &&
      (newsResource.guidance_resource?.metadata as NewsMetadata).image
        ?.thumbnail?.contentUrl
  );
}

export async function getSimilarYouTubeVideo({
  id,
  title,
  description,
}: {
  id: string;
  title: string;
  description: string;
}) {
  const response = await fetch(`${BACKEND_URL}/similar-youtube-video`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      id,
      title,
      description,
    }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export async function getDifferentYouTubeVideo({
  id,
  title,
  description,
  reason,
}: {
  id: string;
  title: string;
  description: string;
  reason: string;
}) {
  const response = await fetch(`${BACKEND_URL}/different-youtube-video`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      id,
      title,
      description,
      reason,
    }),
  });
  const responseBody = await response.json();
  return responseBody;
}

/**
 * Google APIs
 */

export type GoogleApiScope =
  | "https://www.googleapis.com/auth/userinfo.email" // email
  | "https://www.googleapis.com/auth/userinfo.profile" // profile
  | "https://www.googleapis.com/auth/calendar.events" // calendar events
  | "openid";

export interface SimpleGoogleCalendarEvent {
  summary: string; // Title or main heading of the event
  description: string; // Description of the event
  location: string; // Location where the event is taking place
  start: EventDateTime; // Start time of the event
  end: EventDateTime; // End time of the event
  colorId?: string; // (Optional) The color of the calendar event
}

interface EventDateTime {
  dateTime: string; // The date and time in ISO format
  timeZone: string; // The time zone
}

/**
 * get_recommendations batch job
 */

interface UserAssessmentResults {
  prompts: {
    question: string;
    type: string;
    answer: string;
    user_assessment_answer_id: string;
    assessment_question_id: string;
  }[];
  userAssessmentId: string;
  userId: string;
}

export async function get_recommendations({
  care_plan_generation_status_id,
}: {
  care_plan_generation_status_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/user-assessment-chat_gpt_recommendation`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ care_plan_generation_status_id }),
      }
    );
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    return { error: error.message };
  }
}

export async function submitGetRecommendationsJob({
  care_plan_generation_status_id,
}: {
  care_plan_generation_status_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/submit_care_plan_generation_batch_job`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          care_plan_generation_status_id,
        }),
      }
    );
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

/**
 * Onboarding
 */

export enum NotificationType {
  REC_IS_READY = "Recommendations are Ready",
  DOCTOR_REVIEWED = "Doctor has Reviewed",
  UPDATE_POSTED_IN_HUB = "New Hub Update",
  MESSAGE_POSTED_IN_HUB = "New Comment in The Hub",
  MESSAGE_POSTED_IN_CARESPACE = "New Comment in The Discussion",
  TODO = "Todos",
  CARESPACE_DEACTIVATION = "Carespace Deactivation",
  CARESPACE_INVITATION = "Carespace Invitation",
  WORKSPACE_INVITATION_ACCEPTANCE = "Carespace Invitation Acceptance",
  ORGANIZATION_INVITATION = "Organization Invitation",
  WELCOME = "Welcome",
  EXTERNAL_SERVICE = "External Service",
  EXTERNAL_SERVICE_REQUEST = "External Service Request",
  EXTERNAL_DIRECT_MESSAGE = "External Direct Message",
  EXTERNAL_DOCUMENT_SHARING = "External Document Sharing",
}

export async function sendNotification(
  related_object_id: string,
  notif_type: NotificationType
) {
  try {
    const response = await fetch(`${BACKEND_URL}/send_notification`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        related_object_id,
        notif_type,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function submitAssessment(user_assessment_id: string) {
  try {
    const response = await fetch(`${BACKEND_URL}/submit_assessment`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_assessment_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    return { error: error.message };
  }
}

export async function oneSignal(
  user_id: string,
  is_starting_assessment?: boolean,
  is_onboarding?: boolean
) {
  try {
    const response = await fetch(`${BACKEND_URL}/one_signal`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_id,
        is_starting_assessment,
        is_onboarding,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function createUser(
  invitationId: string | undefined | null,
  authId: string,
  isForOrganization: boolean,
  session: Session | null
) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_user`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken(session)}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        invitation_id: invitationId,
        auth_id: authId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        is_for_organization: isForOrganization,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function acceptOrgInvitation(
  authId: string,
  invitationId: string
) {
  try {
    const response = await fetch(`${BACKEND_URL}/accept-org-invitation`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        auth_id: authId,
        invitation_id: invitationId,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function signupWithInvitation(
  password: string,
  invitationId: string | undefined | null,
  isForOrganization: boolean
) {
  try {
    const response = await fetch(`${BACKEND_URL}/invitation_signup`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        invitation_id: invitationId,
        password,
        is_for_organization: isForOrganization,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function renewPlanEntry({
  plan_entry_id,
}: {
  plan_entry_id: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/renew_plan_entry`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ plan_entry_id }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.chatGptConversation],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.planEntries],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.guidePlanEntry],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.baseAllPlanEntries],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.serviceRequests],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.guideEvent],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.guidePlanEntryForCategory],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.guidePlanEntryForCall],
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function updateDyadBasedGuideTasks({
  network_id,
}: {
  network_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/update_dyad_based_guide_tasks`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ network_id }),
      }
    );
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.guidePlanEntry],
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function getLaunchDarklyInfo({ userId }: { userId?: string }) {
  try {
    if (!userId) return null;
    const url = new URL(`${BACKEND_URL}/launch_darkly_information`);
    url.searchParams.append("user_id", userId);

    const response = await fetch(url, {
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useLaunchDarklyInfoQuery = () => {
  const { authUser } = useAuthUser();
  return useQuery({
    queryKey: [QUERY_KEYS.launchDarklyInfo, { userId: authUser?.id }],
    queryFn: () => {
      if (!authUser) return null;
      return getLaunchDarklyInfo({
        userId: authUser.id,
      });
    },
    enabled: !!authUser?.id,
  });
};

async function createPrivateConversation({
  user_ids,
  network_id,
  service_provider_id,
}: {
  user_ids: string[];
  network_id: string;
  service_provider_id?: string | undefined;
}) {
  const response = await fetch(`${BACKEND_URL}/create_private_conversation`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ user_ids, network_id, service_provider_id }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export const useCreatePrivateConversationMutation = () => {
  const { authUser } = useAuthUser();
  return useMutation({
    mutationFn: async ({
      user_id,
      network_id,
      service_provider_id,
    }: {
      user_id?: string | undefined;
      network_id: string;
      service_provider_id?: string | undefined;
    }) => {
      if (!authUser || (!user_id && !service_provider_id)) return null;
      const userIds = user_id ? [authUser.id, user_id] : [authUser.id];
      return createPrivateConversation({
        user_ids: userIds,
        network_id,
        service_provider_id,
      });
    },
  });
};

export async function createCarespace({
  name,
  orgId,
  carespace_creator_id,
}: {
  name: string;
  orgId: string;
  carespace_creator_id: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_carespace`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name,
        org_id: orgId,
        carespace_creator_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function createOrgAndIdentity({
  authId,
  orgRole,
  orgName,
  address,
  city,
  state,
  zip,
}: {
  authId: string;
  orgRole: OrgRoleType;
  orgName: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_org_and_identity`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        auth_id: authId,
        org_role: orgRole,
        org_name: orgName,
        address,
        city,
        state,
        zip,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    // invalidate affected queries
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgs],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgAndIdentities],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.userRole],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.networkIds],
    });
    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

/**
 * search_local_resources
 */

export type YellowPagesListing = z.infer<typeof YellowPagesListingSchema>;

export const useSearchLocalResources = (
  yplocation?: string,
  ypkeyword?: string
) =>
  useQuery({
    queryKey: [QUERY_KEYS.localResources, yplocation, ypkeyword],
    queryFn: async () => {
      const { data } = await searchLocalResources({
        yplocation: yplocation || "",
        ypkeyword: ypkeyword || "",
      });
      return data;
    },
  });

export async function getGoogleMeeting() {
  try {
    const response = await fetch(`${BACKEND_URL}/create_google_meeting`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return { data: responseBody, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export function useBillingPageData(
  hideIneligible: boolean,
  selectedMonth: number | undefined,
  selectedYear: number | undefined
) {
  const organizationIds: string[] | undefined = useActiveOrganizationIds();
  return useQuery({
    queryKey: [
      QUERY_KEYS.billingPageData,
      {
        organizationIds,
        hideIneligible,
        month: selectedMonth,
        year: selectedYear,
      },
    ],
    queryFn: async () => {
      try {
        if (!organizationIds) {
          throw new Error("Please select an organization");
        }

        if (organizationIds.length > 1) {
          throw new Error(
            "The billing data is not available for multiple organizations"
          );
        }

        const response = await fetch(
          `${BACKEND_URL}/get_billing_page_info?organization_id=${organizationIds[0]}&filter_out_ineligible=${hideIneligible}&month=${selectedMonth}&year=${selectedYear}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        if (response.status != 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as BillingPageData;
      } catch (error: any) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}

export const useGetIntercomIdentityHash = () =>
  useQuery({
    queryKey: [QUERY_KEYS.intercomIdentityHash],
    queryFn: getIntercomIdentityHash,
  });

export async function getIntercomIdentityHash() {
  try {
    const response = await fetch(`${BACKEND_URL}/intercom_identifier`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/pdf",
      },
    });
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    return await response.json();
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export const useCreateGoogleMeetingMutation = () =>
  useMutation({
    mutationFn: getGoogleMeeting,
  });

export async function getCarePlanPdfURL(
  care_plan_id: string,
  user_adlo: UserAdlo | undefined
) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/download_care_plan_pdf?care_plan_id=${care_plan_id}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/pdf",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const blob = await response.blob();
    downloadBlob(
      blob,
      `${user_adlo?.first_name}_${user_adlo?.last_name}_care_plan.pdf`
    );
    return { data: blob, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function searchLocalResources({
  yplocation,
  ypkeyword,
}: {
  yplocation: string;
  ypkeyword: string;
}) {
  try {
    const query = qs.stringify({ yplocation, ypkeyword });
    const response = await fetch(
      `${BACKEND_URL}/find_local_resources?${query}`,
      {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    if (response.status != 200) {
      throw new Error(await response.text());
    }
    const responseBody = (await response.json()) as YellowPagesListing[];
    return { data: responseBody, error: null };
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export type AssessmentQuestionAnalytics = {
  dyad_breakdown: Record<string, number>;
  average_age: number;
  sex_breakdown: Record<string, number>;
  caregiver_breakdown: Record<string, number>;
  total_answers: number;
};

export function useAssessmentAnalytics(): UseQueryResult<{
  possible_answers: string[];
  total_breakdown: { [key: string]: AssessmentQuestionAnalytics };
  month_by_month_breakdown: {
    [key: string]: { [key: string]: AssessmentQuestionAnalytics };
  };
  total_patients: number;
}> {
  const organizationIds: string[] | undefined = useActiveOrganizationIds();
  const { questionId, dyad, sex, hasCaregiver, monthRange, ageRange } =
    useAnalyticsPageFilterStore();
  return useQuery({
    queryKey: [
      QUERY_KEYS.assessmentAnalytics,
      {
        organizationIds,
        questionId,
        dyad,
        sex,
        hasCaregiver,
        monthRange,
        ageRange,
      },
    ],
    queryFn: async () => {
      try {
        if (!questionId) {
          return null;
        }

        if (!organizationIds) {
          throw new Error("Please select an organization");
        }

        if (organizationIds.length > 1) {
          throw new Error(
            "The analytics data is not available for multiple organizations"
          );
        }

        const response = await fetch(
          `${BACKEND_URL}/get_user_assessment_analytics?organization_id=${organizationIds[0]}&assessment_question_id=${questionId}&dyad=${dyad}&sex=${sex}&has_caregiver=${hasCaregiver}&month=${monthRange}&age_range=${ageRange}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        );
        if (response.status != 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as {};
      } catch (error: any) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}

export const usePublishCarePlanToEHR = () =>
  useMutation({
    mutationFn: async ({ care_plan_id }: { care_plan_id: string }) => {
      await fetch(`${BACKEND_URL}/publish_care_plan`, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          care_plan_id,
        }),
      });
    },
  });

export async function updateNetworkCareNavigator({
  network_ids,
  care_navigator_id,
}: {
  network_ids: string[];
  care_navigator_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/update-network-care-navigator`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ network_ids, care_navigator_id }),
      }
    );

    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }

    return responseBody;
  } catch (error: any) {
    Sentry.captureException(error);
    return { error: error.message };
  }
}

export async function fetchConversations(
  userId: string | undefined,
  networkIds: string[]
) {
  if (!userId || !networkIds || networkIds.length === 0) {
    return [];
  }

  const queryString = networkIds
    .map((id) => `network_ids=${encodeURIComponent(id)}`)
    .join("&");

  const response = await fetch(
    `${BACKEND_URL}/fetch_conversations?user_id=${userId}&${queryString}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
  const responseBody = await response.json();
  return responseBody as DiscussionMessage[];
}
