import { getRoleLabel } from "backend/resources/orgRole";
import type { PlanEntry } from "backend/resources/planEntry";
import type { UserWithRole } from "backend/resources/userRole";
import {
  getUserInitials,
  useUsersInActiveNetworkAndOrganization,
} from "components/MyPlanPage/utils";
import { ProfileImage } from "components/Profile/ProfileImage";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";

import { Combobox } from "shared/ui/combobox";
import { LabeledContent } from "shared/ui/labeled-content";

export enum UserType {
  ORG_USERS,
  NETWORK_USERS,
  BOTH,
  ORG_USERS_IN_NETWORK,
}

interface WhoProps {
  entry?: PlanEntry | undefined | null;
  isEditing?: boolean;
  setPlanEntryOwner?: any;
  selectedPlanEntryOwnerId: string | undefined;
  networkId?: string | null | undefined;
  userType?: UserType;
  className?: string;
  disabled?: boolean;
  fontSize?: string;
  label?: string;
}

export default function Who({
  entry,
  isEditing,
  setPlanEntryOwner,
  selectedPlanEntryOwnerId,
  networkId,
  userType = UserType.NETWORK_USERS,
  className,
  disabled,
  fontSize,
  label,
}: WhoProps) {
  const { authUser } = useAuthUser();

  // Queries
  const { data: selectedPlanEntryOwner } = useFetchOne(
    {
      equals: { id: selectedPlanEntryOwnerId || entry?.user_id },
    },
    {
      enabled: !!selectedPlanEntryOwnerId || !!entry?.user_id,
    }
  );
  const {
    usersInNetwork,
    usersInOrg,
    usersInOrgAndNetwork,
    orgUsersInNetwork,
  } = useUsersInActiveNetworkAndOrganization(networkId ?? undefined);

  const filteredUsers: UserWithRole[] = {
    [UserType.ORG_USERS]: usersInOrg,
    [UserType.NETWORK_USERS]: usersInNetwork ?? [],
    [UserType.BOTH]: usersInOrgAndNetwork,
    [UserType.ORG_USERS_IN_NETWORK]: orgUsersInNetwork,
  }[userType];

  const userOptions = filteredUsers.map((userWithRole) => {
    const role = getRoleLabel(userWithRole.role);
    return {
      label:
        userWithRole.user.id === authUser?.id
          ? "Me"
          : `${userWithRole.user.first_name} ${userWithRole.user.last_name} (${role})`,
      value: userWithRole.user.id,
      icon: (
        <ProfileImage
          userId={userWithRole.user.id}
          size={20}
        />
      ),
    };
  });

  return (
    <div className="flex items-center gap-2 w-full">
      {isEditing ? (
        <LabeledContent
          className="w-full"
          label={label || ""}>
          <Combobox
            options={userOptions}
            onChange={setPlanEntryOwner}
            defaultValue={selectedPlanEntryOwnerId}
            placeholder="Please Select"
          />
        </LabeledContent>
      ) : (
        getUserInitials(selectedPlanEntryOwner, authUser)
      )}
    </div>
  );
}
