import {
  useCreateGuideEvent,
  useGuideEvent,
  useGuideEventByPlanEntryId,
  useUpdateGuideEvent,
} from "backend/resources/guide/guideCall";
import {
  usePlanEntriesInGuideEvent,
  useUpsertPlanEntryToGuideEvent,
} from "backend/resources/guide/guideEventToPlanEntry";
import type {
  PlanEntry,
  PlanEntryUpdate,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";
import {
  TaskStatus,
  TaskStatusLabel,
  useAllPlanEntriesForCall,
  usePlanEntryData,
  usePlanEntryWithGuideTask,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import type { CanonicalGuideTaskType } from "components/GuideTaskSelectorPopup/GuideTaskSelectorPopup";
import {
  CanonicalGuideTaskFields,
  GuideTaskSelectorPopup,
} from "components/GuideTaskSelectorPopup/GuideTaskSelectorPopup";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import type { TaskTableType } from "components/Tables/TaskTable/TaskTableTypes";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import type { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import { TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { useEffect, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";
import { useNetworkStore } from "state/network/network";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

import { useAuthUser } from "features/users/auth";
import { Checkbox } from "shared/ui/checkbox";
import { Combobox } from "shared/ui/combobox";
import { LabeledContent } from "shared/ui/labeled-content";

const sortRowsByDateAndCategory = (a: TaskTableType, b: TaskTableType) => {
  // Primary sort is Date, Category is secondary
  if (new Date(a.Due).getTime() !== new Date(b.Due).getTime()) {
    return new Date(a.Due) < new Date(b.Due) ? -1 : 1;
  } else {
    return a.Category < b.Category ? -1 : a.Category > b.Category ? 1 : 0;
  }
};

export default function CallManagement({
  planEntryId,
}: {
  planEntryId: string | undefined;
}) {
  const { data: entryData, isLoading: isLoadingEntryData } =
    usePlanEntryWithGuideTask(planEntryId);
  const { data: guideEvent, isLoading: isLoadingGuideEvent } =
    useGuideEventByPlanEntryId(planEntryId);
  const callId = guideEvent?.id;
  const { data: guideCall, isLoading: isGuideCallLoading } =
    useGuideEvent(callId);

  const { data: parentPlanEntry } = usePlanEntryData(
    guideCall?.perform_event_plan_entry_id
  );
  const {
    data: guideTasksInGuideEvent,
    isLoading: isGuideTasksInGuideEventLoading,
  } = usePlanEntriesInGuideEvent(callId);
  const {
    data: allAvailableGuideTasks,
    isLoading: isLoadingAllAvailableGuideTasks,
  } = useAllPlanEntriesForCall(guideCall?.network_id);
  const { status, hideDone, setHideDone, setStatus } = useTaskFilterStore();

  const updateGuideEvent = useUpdateGuideEvent().mutateAsync;
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  const upsertPlanEntryToGuideEvent =
    useUpsertPlanEntryToGuideEvent().mutateAsync;
  const createGuideEvent = useCreateGuideEvent().mutateAsync;

  const [note, setNote] = useState<string>();
  const [localAllAvailableGuideTasks, setLocalAllAvailableGuideTasks] =
    useState(allAvailableGuideTasks);
  const [updatedEntriesInCall, setUpdatedEntriesInCall] = useState<
    TaskWithGuideInfo[]
  >([]);
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  const { authUser } = useAuthUser();
  const networkId = guideCall?.network_id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setHideDone(false);
  }, [setHideDone]);

  useEffect(() => {
    if (guideCall) {
      if (guideCall.notes) {
        setNote(guideCall.notes);
      }
      setActiveNetworkId(guideCall.network_id);
    }
  }, [guideCall, setActiveNetworkId]);

  useEffect(() => {
    if (
      !guideEvent &&
      isLoadingGuideEvent &&
      entryData &&
      entryData.network_id
    ) {
      createGuideEvent({
        network_id: entryData.network_id,
        perform_event_plan_entry_id: entryData.id,
        user_id: entryData.user_id,
        status: TaskStatus.NotStarted,
        title: "Default Title",
        type: "Default Type",
      });
    }
  }, [createGuideEvent, entryData, guideCall, guideEvent, isLoadingGuideEvent]);

  useEffect(() => {
    const planEntriesInGuideEventIds = new Set(
      guideTasksInGuideEvent?.map((row) => row.id)
    );
    const newEntries = allAvailableGuideTasks?.filter(
      (row) =>
        row.network_id === guideCall?.network_id &&
        (isEditing ||
          planEntriesInGuideEventIds.has(row[TaskTableFields.Id])) &&
        parentPlanEntry?.parent_plan_entry_id !== row.id // hide the guide task for calls
    );
    setLocalAllAvailableGuideTasks(newEntries);
  }, [
    isEditing,
    guideTasksInGuideEvent,
    guideCall,
    parentPlanEntry,
    allAvailableGuideTasks,
  ]);

  const filterConfig: FilterConfig = {
    [TaskFilterTypes.STATUS]: true,
    [TaskFilterTypes.HIDE_DONE]: true,
    customAdditionalFilter: (row: TaskWithGuideInfo) => {
      const planEntriesInGuideEventIds = new Set(
        guideTasksInGuideEvent?.map((row) => row.id)
      );
      if (isEditing) {
        // we check if it's the same as the call's network id
        // we could also do this by setting the network_id filter, but then other pages will get affected too.
        return row.network_id === guideCall?.network_id;
      } else {
        return planEntriesInGuideEventIds.has(row[TaskTableFields.Id]);
      }
    },
  };

  // This function is used to update a plan entry locally. It takes a planEntryUpdate object as a parameter.
  // The function first checks if the plan entry already exists in the local state. If it does, it updates the entry with the new data from planEntryUpdate.
  // If the plan entry does not exist in the local state, it creates a new entry using the guide_task object from an entry with the same guide_task in allPlanEntries.
  // The new or updated entry is then added to the updatedEntriesInCall state.
  function updatePlanEntryLocally(planEntryUpdate: PlanEntryUpdate) {
    setLocalAllAvailableGuideTasks((prevEntries) => {
      let updated = false;
      const updatedEntries = prevEntries?.map((entry) => {
        if (entry.id === planEntryUpdate.id) {
          updated = true;
          setUpdatedEntriesInCall([
            ...updatedEntriesInCall,
            { ...entry, ...planEntryUpdate },
          ]);
          return { ...entry, ...planEntryUpdate };
        }
        return entry;
      });

      if (!updated) {
        // create a PlanEntryWithGuideTask object by using the guide_task object
        // in an entry with the same guide_task allPlanEntries
        // TODO: investigate if this still needed
        const matchingRow = allAvailableGuideTasks?.find(
          (row) => row.guide_task_id === planEntryUpdate.guide_task_id
        );
        if (matchingRow) {
          // append the new entry to updatedEntriesInCall
          setUpdatedEntriesInCall((prevEntries) => {
            const matchingRow = allAvailableGuideTasks?.find(
              (row) => row.guide_task_id === planEntryUpdate.guide_task_id
            );
            if (matchingRow) {
              return [...prevEntries, { ...matchingRow, ...planEntryUpdate }];
            }
            return prevEntries;
          });
          updatedEntries?.push({ ...matchingRow, ...planEntryUpdate });
        }
      }
      return updatedEntries;
    });
    return planEntryUpdate as PlanEntry;
  }

  function handleTasksSelectorSubmit(data: CanonicalGuideTaskType[]) {
    for (const row of data) {
      if (row[CanonicalGuideTaskFields.IsSelected] && callId) {
        updatePlanEntryLocally({
          name: row[CanonicalGuideTaskFields.Task],
          guide_task_id: row[CanonicalGuideTaskFields.ID],
          network_id: networkId,
          user_id: authUser?.id,
          status: TaskStatus.Done,
          recurring_interval: row[CanonicalGuideTaskFields.RecurringInterval],
          scheduled_date_time: new Date(Date.now()).toISOString(),
        });
      }
    }
  }

  if (
    !localAllAvailableGuideTasks ||
    isLoadingAllAvailableGuideTasks ||
    isGuideTasksInGuideEventLoading ||
    isGuideCallLoading ||
    isLoadingEntryData
  ) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner className="w-32" />
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4 pb-20 max-w-4xl">
      <GuideTaskSelectorPopup
        isOpen={isAddNewModalOpen}
        onClose={() => setIsAddNewModalOpen(false)}
        handleSubmit={handleTasksSelectorSubmit}
        networkId={networkId}
      />

      <div className="flex gap-5 w-full items-center">
        <p className="text-xl w-[160px]">{"GUIDE Tasks"}</p>
        <div className="flex gap-5 w-full items-center flex-wrap">
          {!isEditing && (
            <ButtonWithIcon
              onClick={() => setIsEditing(true)}
              text={"Add Tasks"}
              icon={IconOption.PLUS}
              size="small"
            />
          )}

          {isEditing ? (
            <div className="flex gap-5">
              {isLoading && <LoadingSpinner className="w-5" />}
              <ActionButtons>
                <ButtonWithIcon
                  onClick={() => setIsEditing(false)}
                  icon={IconOption.CANCEL}
                  text="Cancel"
                  size="small"
                />
                <ButtonWithIcon
                  text="Save"
                  size="small"
                  onClick={async (e: Event) => {
                    e.stopPropagation();

                    if (guideCall) {
                      setIsLoading(true);

                      Promise.all(
                        updatedEntriesInCall?.map((entry) =>
                          updatePlanEntry(entry)
                        ) ?? []
                      ).catch((error) =>
                        console.error("Error updating entries:", error)
                      );

                      await updateGuideEvent({
                        ...guideCall,
                        completed_at: new Date().toISOString(),
                        status: TaskStatus.Done,
                        notes: note ?? null,
                      });

                      if (callId) {
                        await Promise.all(
                          updatedEntriesInCall
                            ?.filter(
                              (entry) => entry.status === TaskStatus.Done
                            )
                            .map((entry) =>
                              upsertPlanEntryToGuideEvent({
                                guide_event_id: callId,
                                plan_entry_id: entry.id,
                              })
                            ) ?? []
                        );
                      }
                    }

                    setIsEditing(false);
                  }}
                  icon={IconOption.CHECKMARK}
                />
              </ActionButtons>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex gap-8 items-center">
        <Combobox
          options={[
            { value: "All", label: "All" },
            ...Object.values(TaskStatus).map((status) => ({
              label: TaskStatusLabel[status],
              value: status,
            })),
            { value: "Overdue", label: "Overdue" },
          ]}
          value={status || "All"}
          onChange={(status) => setStatus(status)}
        />

        {status === "All" && (
          <LabeledContent
            label="Hide Done"
            orientation="horizontal">
            <Checkbox
              checked={hideDone}
              onCheckedChange={(checked) => setHideDone(checked)}
            />
          </LabeledContent>
        )}
      </div>

      {localAllAvailableGuideTasks?.length > 0 ? (
        <TaskTable
          updateTask={updatePlanEntryLocally}
          data={localAllAvailableGuideTasks}
          sortFunction={sortRowsByDateAndCategory}
          filterConfig={filterConfig}
          hiddenColumns={[TaskTableFields.Carespace, TaskTableFields.CreatedAt]}
          handleClick={!isEditing ? () => {} : undefined}
          isLoading={isGuideCallLoading || isGuideTasksInGuideEventLoading}
        />
      ) : (
        <p className="text-sm text-zinc-400">
          No tasks selected. Click Add above to add tasks for this call.
        </p>
      )}
    </div>
  );
}
