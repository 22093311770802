import { zodResolver } from "@hookform/resolvers/zod";
import { uploadProfileImageMutation } from "backend/resources/storage/storage";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { ProfileImage } from "components/Profile/ProfileImage";
import { useAuthUser } from "features/users/auth";
import type { ChangeEvent } from "react";
import { useRef, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import type {
  MultiPageFormProps,
  ResponsiveModalWizardProps,
} from "shared/forms/types";
import { PhoneNumberSchema } from "shared/forms/types";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import * as z from "zod";

import { useUpdate } from "features/users/queries/hooks";

export const editProfileFormSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  cellNumber: PhoneNumberSchema,
  profileImage: z.string().optional(),
});

export const useEditProfileForm = (
  defaultValues?: z.infer<typeof editProfileFormSchema>
) =>
  useForm<z.infer<typeof editProfileFormSchema>>({
    resolver: zodResolver(editProfileFormSchema),
    defaultValues: defaultValues ?? {},
    mode: "onSubmit",
  });

export type EditProfileFormHook = UseFormReturn<
  z.infer<typeof editProfileFormSchema>
>;

export const editProfileFormId = "edit-profile-form";

export function EditProfileForm({ onClose }: ResponsiveModalWizardProps) {
  const { authUser } = useAuthUser();
  const [isSending, setIsSending] = useState(false);
  const form = useEditProfileForm();
  const updateUser = useUpdate({ equals: { id: authUser?.id } }).mutateAsync;

  async function handleSubmit(
    validatedForm: z.infer<typeof editProfileFormSchema>
  ) {
    setIsSending(true);
    await updateUser({
      first_name: validatedForm.firstName,
      last_name: validatedForm.lastName,
      cell_number: validatedForm.cellNumber,
      profile_image: validatedForm.profileImage ?? null,
    });
    setIsSending(false);
  }

  return (
    <ResponsiveModal
      isOpen={true}
      closeText="Back"
      title={"Edit Profile"}
      isNetworkCallInProgress={isSending}
      onClose={() => {
        onClose();
        form.reset();
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={() => {
              onClose();
              form.reset();
            }}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={async () => {
              await form.handleSubmit((values) => {
                handleSubmit(values);
                onClose();
                form.reset();
              })();
            }}
          />
        </>
      }>
      {isSending ? (
        <LoadingSpinner className="w-6 h-6" />
      ) : (
        <EditProfileComponent form={form} />
      )}
    </ResponsiveModal>
  );
}

function EditProfileComponent(
  props: MultiPageFormProps<z.infer<typeof editProfileFormSchema>>
) {
  const { form } = props;
  const { authUser } = useAuthUser();
  const inputRef = useRef<HTMLInputElement>(null);

  const uploadProfileImage = uploadProfileImageMutation().mutateAsync;

  return (
    <Form
      form={form}
      id={editProfileFormId}>
      <div className="flex flex-col relative w-full items-center">
        <ButtonWithIcon
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            inputRef.current?.click();
          }}
          text={""}
          className="absolute -bottom-3 left-1/2 bg-white rounded-full"
          icon={IconOption.EDIT}
        />
        <input
          type="file"
          accept="image/*"
          className="hidden"
          ref={inputRef}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files && e.target.files[0];
            if (!file || !authUser) {
              return;
            }

            e.target.value = "";

            const { data, error } = await uploadProfileImage(file);
            if (!error) {
              form.setValue("profileImage", data?.path);
            }
          }}
        />
        <ProfileImage
          size={64}
          className=" w-16 h-16"
          userId={authUser?.id}
          tempProfileImageFilename={form.getValues("profileImage")}
        />
      </div>
      <FormField
        control={form.control}
        name="firstName"
        label="First Name"
        render={({ field }) => <Input {...field} />}
      />
      <FormField
        control={form.control}
        name="lastName"
        label="Last Name"
        render={({ field }) => <Input {...field} />}
      />
      <FormField
        control={form.control}
        name="cellNumber"
        label="Cell Number"
        render={({ field }) => <PhoneNumberFormInput {...field} />}
      />
    </Form>
  );
}
