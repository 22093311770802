import * as Sentry from "@sentry/react";

import { Title } from "shared/ui/title";
import { SuperSuperUserRoute, useAppNavigate } from "lib/routing";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "shared/ui/form";
import { OrganizationRole } from "features/users/constants";
import { z } from "zod";
import { PhoneNumberSchema } from "shared/forms/types";
import { Input } from "shared/ui/input";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { RadioGroup, RadioGroupItem } from "shared/ui/radio-group";
import { ORG_ROLE_OPTIONS } from "backend/resources/userRole/types";
import { Button } from "shared/ui/button";
import { Text } from "shared/ui/text";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "shared/hooks/use-toast";
import { queryClient } from "app";
import { queryKeys } from "features/users/queries";
import { NotificationType, sendNotification } from "backend/functions";
import {
  useFetchOne,
  useInviteUserToOrg,
} from "features/organizations/queries/hooks";
import { LoadingSpinner } from "components/LoadingSpinner";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";

const formSchema = z.object({
  organization_id: z.string(),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({ message: "Invalid email" }),
  cell_number: PhoneNumberSchema,
  org_role: z.nativeEnum(OrganizationRole),
  is_superuser: z.boolean(),
});

type FormSchema = z.infer<typeof formSchema>;

export default function NewMember() {
  useRequireSuperOrg();
  const { toast } = useToast();
  const {
    data: superOrg,
    error,
    isLoading,
  } = useFetchOne({
    equals: { is_super_org: true },
  });
  const inviteToOrg = useInviteUserToOrg().mutateAsync;
  const navigate = useAppNavigate();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      organization_id: superOrg?.id,
      first_name: "",
      last_name: "",
      email: "",
      cell_number: "",
      org_role: OrganizationRole.CARE_NAVIGATOR,
      is_superuser: true,
    },
  });

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  if (error || !superOrg) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Text>Error loading organization. Please reload the page.</Text>
      </div>
    );
  }

  async function onSubmit(data: FormSchema) {
    try {
      const inviteData = await inviteToOrg({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        cell_number: data.cell_number,
        role: data.org_role,
        organization_id: data.organization_id,
        is_superuser: data.org_role === OrganizationRole.ADMIN,
      });
      await sendNotification(
        inviteData.id,
        NotificationType.ORGANIZATION_INVITATION
      );

      queryClient.invalidateQueries({
        queryKey: queryKeys.all,
      });

      toast({
        title: "Success",
        description: "An invitation has been sent to the user",
      });
      navigate({
        path: SuperSuperUserRoute.MEMBERS,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Sorry, something went wrong",
        description: "Please contact support for help.",
        variant: "destructive",
      });
    }
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6 max-w-2xl">
      <Title>New Member</Title>
      <p className="mb-8">This user will have access to all organizations</p>
      <Form
        form={form}
        onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="first_name"
          label="First Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="last_name"
          label="Last Name"
          render={({ field }) => <Input {...field} />}
        />

        <FormField
          control={form.control}
          name="email"
          label="Email"
          render={({ field }) => (
            <Input
              {...field}
              type="email"
            />
          )}
        />

        <FormField
          control={form.control}
          name="cell_number"
          label="Phone Number"
          render={({ field }) => (
            <PhoneNumberInput
              disabled={field.disabled}
              cellPhone={field.value ?? undefined}
              setCellPhone={field.onChange}
            />
          )}
        />

        <FormField
          control={form.control}
          name="org_role"
          label="Role"
          render={({ field }) => (
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex flex-col space-y-1">
              {ORG_ROLE_OPTIONS.map((option) => (
                <FormItem
                  key={option.value}
                  className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value={option.value} />
                  </FormControl>
                  <FormLabel>{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          )}
        />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          className="my-8">
          Save
        </Button>
      </Form>
    </div>
  );
}
