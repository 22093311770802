import logo from "assets/logo.png";
import SupportIcon from "assets/support.svg?react";

import { Button } from "shared/ui/button";
import { Dialog } from "shared/ui/dialog";
import { Text } from "shared/ui/text";

export function Header() {
  return (
    <header className="flex flex-row items-center gap-4 h-14 shrink-0 bg-white border-b border-neutral-200">
      <div className="w-2 h-full bg-brand-orange" />

      <img
        src={logo}
        alt="Craniometrix logo"
        className="w-20"
      />

      <Dialog
        title="We're here to help!"
        description="User help message"
        content={
          <Text>
            Please send any issues to us at{" "}
            <a
              className="text-brand-orange"
              href="mailto:support@craniometrix.com">
              support@craniometrix.com
            </a>{" "}
            and we'll get back to you as soon as possible
          </Text>
        }>
        <Button
          variant="ghost"
          className="ml-auto">
          <SupportIcon />
        </Button>
      </Dialog>
    </header>
  );
}
