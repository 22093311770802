import type { Factor } from "@supabase/supabase-js";
import { TextInput } from "components/TextInput";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";
import useMFA from "hooks/useMFA/useMFA";
import { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Button } from "../Button/Button";

const MFAPhone = () => {
  const {
    getFactorByType,
    enrollPhoneFactor,
    setCurrentFactorId,
    currentFactorId,
    executeChallenge,
    verifyChallenge,
    challengeId,
    error,
    setError,
    factorsRetrieved,
  } = useMFA();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const { authUser } = useAuthUser();
  const { data: user } = useFetchOne(
    { equals: { id: authUser?.id } },
    { enabled: !!authUser }
  );
  const [cellPhone, setCellPhone] = useState<string | null>(
    user?.cell_number ?? null
  );
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [phoneFactor, setPhoneFactor] = useState<Factor | undefined>();

  const handleSendCode = useCallback(async () => {
    if (!cellPhone) return;

    let factorId = null;

    if (!phoneFactor || phoneFactor.status !== "verified") {
      factorId = await enrollPhoneFactor(cellPhone);
    } else {
      factorId = phoneFactor.id;
      setCurrentFactorId(phoneFactor.id);
    }

    if (factorId) {
      await executeChallenge(factorId);
      setCodeSent(true);
    }
  }, [
    enrollPhoneFactor,
    getFactorByType,
    setCurrentFactorId,
    executeChallenge,
    setCodeSent,
  ]);

  const handleVerifyFactor = useCallback(async () => {
    if (!verificationCode || !challengeId || !currentFactorId) return;

    await verifyChallenge(currentFactorId, challengeId, verificationCode);
  }, [verifyChallenge, verificationCode, challengeId, currentFactorId]);

  useEffect(() => {
    if (factorsRetrieved) {
      const phoneFactor = getFactorByType("phone");
      setPhoneFactor(phoneFactor);
    }
  }, [factorsRetrieved]);

  return (
    <main className="min-h-[320px] w-full space-y-6 p-6">
      <header className="w-full">
        <h1 className="font-medium text-2xl text-gray-800 text-center">
          Text message Authentication
        </h1>
      </header>

      <section className="flex justify-center gap-3">
        {challengeId ? (
          <p>The verification code was sent to your phone</p>
        ) : (
          <>
            <div className="">
              <PhoneInput
                className={
                  "flex h-10 w-full py-2 pl-2 text-sm rounded-md border border-neutral-200 bg-white ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                }
                international
                value={cellPhone ?? undefined}
                defaultCountry="US"
                placeholder="Enter phone number"
                onFocus={() => {}}
                onChange={(value) => {
                  setCellPhone(value?.toString() ?? null);
                }}
                disabled={phoneFactor?.status === "verified"}
              />
            </div>

            <Button
              className="bg-green-500"
              disabled={!cellPhone}
              onClick={handleSendCode}>
              Send code
            </Button>
          </>
        )}
      </section>

      {codeSent && (
        <section className="flex justify-center">
          <p className="text-center text-gray-800">
            Enter the 6-digit sent to your phone number
          </p>
        </section>
      )}

      <section className="flex gap-3 items-center justify-center">
        <TextInput
          data-hj-allow
          type="text"
          onChange={(e) => {
            setVerificationCode(e.target.value);
            setError(null);
          }}
          className="text-xs"
          placeholder="6-digit code"
          value={verificationCode}
        />
        <Button onClick={handleVerifyFactor}>Verify</Button>
      </section>

      <section className="">
        {error && (
          <p className="text-red-500 mt-2 text-sm text-center">{error}</p>
        )}
      </section>
    </main>
  );
};

export default MFAPhone;
