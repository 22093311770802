import { supabase } from "clients/supabaseClient";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { TextInput } from "components/TextInput";
import { useAuthUser } from "features/users/auth";
import { useWindowSize } from "hooks/useWindowSize";
import type { ChangeEvent } from "react";
import { useState } from "react";

interface Props {
  close: () => void;
  bookmarkId: string;
  tags?: any[] | null;
  refetchTags: () => void;
  isLoadingTags: boolean;
  isFetchingTags: boolean;
}

export function AddTagsPopup(props: Props) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={props.close}>
        <AddTagsPopupComponent {...props} />
      </BottomSheet>
    );
  } else {
    return (
      <Popup
        showCloseButton={true}
        close={props.close}>
        <AddTagsPopupComponent {...props} />
      </Popup>
    );
  }
}

function AddTagsPopupComponent({
  close,
  bookmarkId,
  tags,
  refetchTags,
  isLoadingTags,
  isFetchingTags,
}: Props) {
  const [error, setError] = useState<string>();
  const [tag, setTag] = useState<string>("");
  const { authUser } = useAuthUser();
  // const setIsDisabled = useActionBarStore((state) => state.setIsDisabled);

  async function persistTag(tag: string) {
    if (authUser?.id) {
      const { data: existingTag, error } = await supabase
        .from("tag")
        .select()
        .eq("name", tag)
        .limit(1)
        .maybeSingle();
      if (!existingTag && !error) {
        // Create a new tag
        const { data: newlyCreatedTag, error } = await supabase
          .from("tag")
          .insert({ created_by_user_id: authUser.id, name: tag })
          .select();
        if (newlyCreatedTag && newlyCreatedTag.length > 0) {
          const { data, error } = await supabase
            .from("user_bookmark_to_tag")
            .insert({
              tag_id: newlyCreatedTag[0].id,
              user_bookmark_id: bookmarkId,
            })
            .select();
          if (data && !error) {
            setTag("");
            refetchTags();
          } else {
            setError("Something went wrong.");
          }
        } else {
          setError("Something went wrong.");
        }
      } else if (existingTag?.id) {
        const { data, error } = await supabase
          .from("user_bookmark_to_tag")
          .insert({ tag_id: existingTag.id, user_bookmark_id: bookmarkId })
          .select();
        if (data && data.length > 0) {
          setTag("");
          refetchTags();
        } else {
          setError("Something went wrong.");
        }
      } else {
        setError("Something went wrong.");
      }
    }
  }

  function addTag(tag: string) {
    if (!tag) {
      setError("Please add an interest");
      return;
    }
    if (tags?.find((t) => t.tag.name === tag)) {
      setError("You have already added this tag");
      return;
    }
    if (tag) {
      persistTag(tag);
      setTag("");
    }
  }

  function onChangeTag(e: ChangeEvent<HTMLInputElement>) {
    setError("");
    const userInput = e.currentTarget.value;
    const splitByComma = userInput.split(",");
    if (splitByComma.length > 1) {
      addTag(splitByComma[0]);
      setTag("");
    } else {
      setTag(e.currentTarget.value);
    }
    setTag(e.currentTarget.value);
  }

  async function removeTag(tagId: string) {
    setError("");
    const { data, error } = await supabase
      .from("user_bookmark_to_tag")
      .delete()
      .eq("tag_id", tagId)
      .eq("user_bookmark_id", bookmarkId)
      .select();
    if (data && data.length > 0) {
      refetchTags();
    } else {
      setError("Something went wrong.");
    }
  }

  return (
    <div className="pb-20 md:pb-0 px-10 md:px-0 flex flex-col gap-3 min-w-[300px]">
      <p className="text-xl">Add / remove tags</p>
      <div className="mt-6 flex gap-2">
        <TextInput
          data-hj-allow
          className="w-[300px]"
          onChange={onChangeTag}
          value={tag}
          placeholder="ex: anxiety, agitation, hygiene"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addTag(tag);
            }
          }}
        />
        <ButtonWithIcon
          text="Add"
          size={"small"}
          onClick={(e: any) => {
            e.preventDefault();
            addTag(tag);
          }}
          icon={IconOption.PLUS}
        />
      </div>
      <p className="font-light">(separate each tag with a comma)</p>
      <div className="flex flex-wrap gap-4 mt-4 max-w-[400px]">
        {isLoadingTags || isFetchingTags ? (
          <LoadingSpinner className="w-10 h-10" />
        ) : (
          tags?.map((tag) => (
            <div
              key={tag.id}
              className="flex gap-2 items-center px-4 py-1 border-brand-orange border-2 text-brand-orange font-semibold rounded-3xl">
              {tag.tag.name}
              <button
                type="button"
                className="self-end hover:scale-[130%]"
                onClick={() => removeTag(tag.tag.id)}>
                <span
                  className="text-2xl"
                  aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
          ))
        )}
      </div>
      {error && <p className="text-red-500 text-sm text-center">{error}</p>}
      <FooterButtons>
        <ButtonWithIcon
          text="Done"
          onClick={close}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
