import { useUserRolesInNetwork } from "backend/resources/userRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { RadioButtonsGroup } from "components/RadioGroup";
import { useAuthUser } from "features/users/auth";

interface AssessmentCaregiverSelectionPageProps {
  selectedCaregiver: string | undefined;
  setSelectedCaregiver: (value: string) => void;
}

export default function AssessmentCaregiverSelectionPage({
  selectedCaregiver,
  setSelectedCaregiver,
}: AssessmentCaregiverSelectionPageProps) {
  const { data: userRoles } = useUserRolesInNetwork();
  const { authUser } = useAuthUser();
  return (
    <div>
      <RadioButtonsGroup
        id="caregiver-selection"
        options={[
          { value: authUser?.id ?? "", label: "Skip Caregiver Selection" },
          ...(userRoles
            ?.filter((userRole) =>
              [
                NetworkRoleType.CAREGIVER,
                NetworkRoleType.PRIMARY_CAREGIVER,
              ].includes(userRole.role as NetworkRoleType)
            )
            .map((userRole) => ({
              value: userRole.user.id,
              label: `${userRole.user.first_name} ${userRole.user.last_name}`,
            })) ?? []),
        ]}
        value={selectedCaregiver}
        onChange={(value) => setSelectedCaregiver(value)}
        row={false}
      />
    </div>
  );
}
