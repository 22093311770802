import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { OrganizationRole } from "features/users/constants";

import { TABLE_NAME } from "../constants";
import type {
  OrganizationInsert,
  OrganizationUpdate,
  TableName,
} from "../types";

const all = ["organizations"];
const lists = [...all, "list"];
const detail = [...all, "detail"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
  detail: (filter: Filter<TableName>) => [...detail, filter],
};

export const fields = `
    id,
    created_at,
    name,
    address,
    city,
    state,
    zip,
    is_super_org
  `;

export const select = () => supabase.from(TABLE_NAME).select(fields);

export const update = (organizationUpdate: OrganizationUpdate) =>
  supabase.from(TABLE_NAME).update(organizationUpdate);

export const insert = (organizationInsert: OrganizationInsert) =>
  supabase.from(TABLE_NAME).insert(organizationInsert).select(fields);

export const assignUserToOrg = ({
  userId,
  organizationId,
  role,
  isSuperuser,
}: {
  userId: string;
  organizationId: string;
  role: OrganizationRole;
  isSuperuser?: boolean;
}) =>
  supabase
    .from("organization_role")
    .insert({
      user_id: userId,
      organization_id: organizationId,
      is_superuser: isSuperuser ?? role === OrganizationRole.ADMIN,
      role,
    })
    .select()
    .maybeSingle();

export const inviteUserToOrg = ({
  first_name,
  last_name,
  email,
  cell_number,
  organization_id,
  role,
  is_superuser = false,
}: {
  first_name: string;
  last_name: string;
  email: string;
  cell_number: string;
  organization_id: string;
  role: OrganizationRole;
  is_superuser: boolean;
}) =>
  supabase
    .from("organization_invitation")
    .insert({
      first_name,
      last_name,
      invited_email: email,
      cell_number,
      organization_id,
      role_type: role,
      is_superuser,
    })
    .select()
    .maybeSingle();
