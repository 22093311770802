import AdminIcon from "assets/nav/admin_icon_orange.png";
import AssessmentsIcon from "assets/nav/assessments_icon_orange.png";
import BillingIcon from "assets/nav/billing_icon_orange.png";
import CarePlanIcon from "assets/nav/care_plan_icon_orange.png";
import CarespacesIcon from "assets/nav/carespace_icon_orange.png";
import DashboardIcon from "assets/nav/dashboard_orange.png";
import DirectoryIcon from "assets/nav/directory_icon_orange.png";
import DiscussionIcon from "assets/nav/discussion_icon_orange.png";
import EducationIcon from "assets/nav/education_icon_orange.png";
import FamilyIcon from "assets/nav/family_icon_orange.png";
import FavoritesIcon from "assets/nav/favorites_icon_orange.png";
import HomeIcon from "assets/nav/home_icon_orange.png";
import LocalSearchPng from "assets/nav/local-search-icon.png";
import MyCareIcon from "assets/nav/my_care_icon_orange.png";
import ServiceHubIcon from "assets/nav/service_hub_icon_orange.png";
import ServicesIcon from "assets/nav/services_icon_orange.png";
import TasksIcon from "assets/nav/tasks_icon_orange.png";
import ToolsIcon from "assets/nav/tools_icon_orange.png";
import UpdateIconSvg from "assets/nav/updates.svg?react";
import SearchPNG from "assets/search.png";
import { NetworkRoleType } from "backend/resources/userRole/types";
import {
  CareCentralRoute,
  CarePilotRoute,
  SharedRoute,
  SuperSuperUserRoute,
} from "lib/routing";
import type { ReactNode } from "react";
import { Building, Users } from "lucide-react";

export enum NavItemName {
  Home = "Home",
  MyDashboard = "My Dashboard",
  Favorites = "Favorites",
  Tasks = "Tasks",
  MyCare = "My Care",
  Admin = "Admin",
  FamilyHub = "Family Hub",
  Updates = "Updates",
  Discussions = "Discussions",
  Services = "Services",
  Education = "Education",
  PatientSearch = "Patient Search",
  Carespaces = "Carespaces",
  ServiceHub = "Service Hub",
  CarePlan = "Coaching Plan",
  Assessments = "Assessments",
  Tools = "Tools",
  Directory = "Directory",
  Billing = "Billing",
  Organizations = "Organizations",
  Members = "Members",
}

export const NAV_ITEMS_CONFIG: Record<
  NavItemName,
  { route: string; icon: ReactNode; subNavItems?: SubNavItem[] }
> = {
  Home: {
    route: SharedRoute.HOME,
    icon: (
      <img
        src={HomeIcon}
        alt="Home"
      />
    ),
  },
  "My Dashboard": {
    route: SharedRoute.HOME,
    icon: (
      <img
        src={DashboardIcon}
        alt="My Dashboard"
      />
    ),
  },
  Favorites: {
    route: CarePilotRoute.FAVORITES,
    icon: (
      <img
        src={FavoritesIcon}
        alt="Favorites"
      />
    ),
  },
  Tasks: {
    route: CarePilotRoute.PLAN,
    icon: (
      <img
        src={TasksIcon}
        alt="Tasks"
      />
    ),
  },
  "My Care": {
    route: CarePilotRoute.MY_CARE,
    icon: (
      <img
        src={MyCareIcon}
        alt="My Care"
      />
    ),
  },
  Admin: {
    route: SharedRoute.ADMIN,
    icon: (
      <img
        src={AdminIcon}
        alt="Admin"
      />
    ),
  },
  "Family Hub": {
    route: CarePilotRoute.FAMILY_HUB,
    icon: (
      <img
        src={FamilyIcon}
        alt="Family Hub"
      />
    ),
  },
  Updates: { route: CarePilotRoute.FAMILY_HUB, icon: <UpdateIconSvg /> },
  Discussions: {
    route: CareCentralRoute.DISCUSSIONS,
    icon: (
      <img
        src={DiscussionIcon}
        alt="Discussions"
      />
    ),
  },
  Services: {
    route: CarePilotRoute.LOCAL_SEARCH,
    icon: (
      <img
        src={LocalSearchPng}
        alt="Services"
      />
    ),
  },
  Education: {
    route: CarePilotRoute.EDUCATION,
    icon: (
      <img
        src={EducationIcon}
        alt="Education"
      />
    ),
  },
  "Patient Search": {
    route: SuperSuperUserRoute.PATIENT_SEARCH,
    icon: (
      <img
        src={SearchPNG}
        alt="Patient Search"
      />
    ),
  },
  Carespaces: {
    route: CareCentralRoute.CARESPACES,
    icon: (
      <img
        src={CarespacesIcon}
        alt="Carespaces"
      />
    ),
  },
  "Service Hub": {
    route: CareCentralRoute.SERVICE_HUB,
    icon: (
      <img
        src={ServiceHubIcon}
        alt="Service Hub"
      />
    ),
  },
  "Coaching Plan": {
    route: CarePilotRoute.CARE_PLAN,
    icon: (
      <img
        src={CarePlanIcon}
        alt="Care Plan"
      />
    ),
  },
  Directory: {
    route: CareCentralRoute.DIRECTORY,
    icon: (
      <img
        src={DirectoryIcon}
        alt="Directory"
      />
    ),
  },
  Assessments: {
    route: CarePilotRoute.ASSESSMENTS,
    icon: (
      <img
        src={AssessmentsIcon}
        alt="Assessments"
      />
    ),
  },
  Billing: {
    route: CareCentralRoute.BILLING_PAGE,
    icon: (
      <img
        src={BillingIcon}
        alt="Billing"
      />
    ),
  },
  Tools: {
    route: CarePilotRoute.ASSESSMENTS,
    icon: (
      <img
        src={ToolsIcon}
        alt="Tools"
        className="w-6 h-6"
      />
    ),
    subNavItems: [
      {
        name: "Favorites",
        pathname: CarePilotRoute.FAVORITES,
        icon: (
          <img
            className="w-4 h-4"
            src={FavoritesIcon}
            alt="Favorites"
          />
        ),
      },
      {
        name: "Assessments",
        pathname: CarePilotRoute.ASSESSMENTS,
        icon: (
          <img
            className="w-4 h-4"
            src={AssessmentsIcon}
            alt="Assessments"
          />
        ),
      },
      {
        name: "Services",
        pathname: CarePilotRoute.LOCAL_SEARCH,
        icon: (
          <img
            className="w-4 h-4"
            src={ServicesIcon}
            alt="Services"
          />
        ),
      },
      {
        name: "Education",
        pathname: CarePilotRoute.EDUCATION,
        icon: (
          <img
            className="w-4 h-4"
            src={EducationIcon}
            alt="Education"
          />
        ),
      },
    ],
  },
  Organizations: {
    route: SuperSuperUserRoute.ORGANIZATIONS,
    icon: <Building size={20} />,
  },
  Members: {
    route: SuperSuperUserRoute.MEMBERS,
    icon: <Users size={20} />,
  },
};

export type SubNavItem = {
  name: string;
  pathname: string;
  icon: ReactNode;
};

export class NavItem {
  name: NavItemName;

  icon: ReactNode;

  route: string;

  isExpanded: boolean;

  subNavItems?: SubNavItem[];

  constructor(name: NavItemName) {
    this.name = name;
    this.icon = NAV_ITEMS_CONFIG[name].icon;
    this.route = NAV_ITEMS_CONFIG[name].route;
    this.isExpanded = false;
    this.subNavItems = NAV_ITEMS_CONFIG[name].subNavItems;
  }
}

export function determineItemsBasedOnRole(
  isSuperOrg: boolean,
  role: NetworkRoleType
) {
  const itemNames: NavItemName[] = [];

  const isAdmin = role === NetworkRoleType.ADMIN;

  const isProfessional =
    role === NetworkRoleType.DOCTOR ||
    role === NetworkRoleType.CARE_NAVIGATOR ||
    role === NetworkRoleType.ADMIN;

  const isFamily =
    role === NetworkRoleType.FAMILY_MEMBER ||
    role === NetworkRoleType.PRIMARY_CAREGIVER ||
    role === NetworkRoleType.CAREGIVER;

  if (isSuperOrg) {
    // itemNames.push(
    //   NavItemName.MyDashboard,
    //   NavItemName.Discussions,
    //   NavItemName.Carespaces,
    //   NavItemName.ServiceHub,
    //   NavItemName.Directory,
    //   NavItemName.PatientSearch
    // );
  }

  if (isSuperOrg && isAdmin) {
    itemNames.push(NavItemName.Organizations, NavItemName.Members);
  }

  if (!isSuperOrg && isProfessional) {
    itemNames.push(
      NavItemName.MyDashboard,
      NavItemName.Discussions,
      NavItemName.Carespaces,
      NavItemName.ServiceHub,
      NavItemName.Directory
    );
  }

  if (!isSuperOrg && isAdmin) {
    itemNames.push(NavItemName.Billing, NavItemName.Admin);
  }

  if (!isSuperOrg && isFamily) {
    itemNames.push(
      NavItemName.Home,
      NavItemName.Tasks,
      NavItemName.CarePlan,
      NavItemName.Discussions,
      NavItemName.FamilyHub,
      NavItemName.Tools,
      NavItemName.MyCare
    );
  }

  return itemNames.map((name) => new NavItem(name));
}
