import type { RouteObject } from "react-router-dom";

export type RoutLayoutObject = RouteObject & {
  routeLayout?: RouteLayout;
  hideSideNav?: boolean;
};

export enum RouteLayout {
  PROTECTED_ROUTE,
  PROTECTED_ROUTE_WITH_APP_LAYOUT,
  PUBLIC,
  RESTRICTED,
}
