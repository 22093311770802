import PrivacyPolicyIcon from "assets/nav/privacy_policy_icon_orange.png";
import TermsOfServiceICon from "assets/nav/terms_and_conditions_icon_orange.png";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  CollapseSidebarButton,
  CollapsibleNavItem,
  NavItem,
} from "components/NavBar";
import { useRole } from "hooks/role/useRole";
import { ExternalRoute } from "lib/routing";
import { useEffect } from "react";
import { useSideNavStore } from "state/navItems";
import { NAV_ITEMS_CONFIG } from "state/navItems/navItemModels";
import { useActiveOrganizationIds } from "state/organization/organization";

export function SideNav() {
  const activeOrgs = useActiveOrganizationIds();
  const isMinimized = useSideNavStore((state) => state.isMinimized);
  const { role, isLoading } = useRole();

  const items = useSideNavStore((state) => state.items);
  const configureNavContext = useSideNavStore(
    (state) => state.configureNavContext
  );

  useEffect(() => {
    // Only wait for loading to complete, don't depend on role
    if (isLoading) return;

    configureNavContext({
      role: role || NetworkRoleType.CAREGIVER, // Default to CARE_PILOT if no role
      isMobile: false,
      isSuperOrg: activeOrgs ? activeOrgs.length > 1 : false,
    });
  }, [role, activeOrgs, isLoading, configureNavContext]);

  // Show loading state while data is being fetched
  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-16 border-r border-neutral-200 h-full">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    );
  }

  return (
    <div
      className={`${
        isMinimized ? "w-16" : "w-44"
      } border-r border-neutral-200 h-full shrink-0 relative`}>
      {/* content */}
      <div className="flex flex-col h-full gap-5 p-3">
        <div
          className={`flex w-full ${
            isMinimized ? "justify-start" : "justify-end"
          }`}>
          <CollapseSidebarButton />
        </div>

        <div className="flex flex-col flex-grow gap-5">
          {items.map((item) => {
            if (item.subNavItems) {
              return (
                <CollapsibleNavItem
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  subNavItems={item.subNavItems}
                  isExpanded={!!item.isExpanded}
                />
              );
            } else {
              return (
                <NavItem
                  key={item.name}
                  name={item.name}
                  icon={item.icon}
                  pathname={NAV_ITEMS_CONFIG[item.name].route}
                />
              );
            }
          })}
        </div>

        {/* bottom nav  */}
        <div className="flex flex-col gap-5 pb-5">
          <NavItem
            key="Terms of Service"
            name="Terms of Service"
            icon={
              <img
                src={TermsOfServiceICon}
                className="w-6 h-6"
              />
            }
            pathname={ExternalRoute.TERMS_OF_SERVICE}
            isExternalLink
            size={"small"}
          />
          <NavItem
            key={"Privacy Policy"}
            name="Privacy Policy"
            icon={
              <img
                src={PrivacyPolicyIcon}
                className="w-6 h-6"
              />
            }
            pathname={ExternalRoute.PRIVACY_POLICY}
            isExternalLink
            size={"small"}
          />
        </div>
      </div>
    </div>
  );
}
