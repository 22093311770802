import { ProfileImage } from "components/Profile/ProfileImage";
import { useAuthUser } from "features/users/auth";
import { useClickOutside } from "hooks/useClickOutside";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useRef, useState } from "react";

export function ProfileMenu() {
  const userMenuRef = useRef<HTMLDivElement>(null);
  const userMenuTriggerRef = useRef<HTMLButtonElement>(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { authUser, logout } = useAuthUser();
  const navigate = useAppNavigate();
  useClickOutside({
    ref: userMenuRef,
    isEnabled: isUserMenuOpen,
    onClickOutside: () => setIsUserMenuOpen(false),
    excludedElementRefs: [userMenuTriggerRef],
  });

  return (
    <div className="flex justify-end md:items-center w-full md:w-auto">
      {authUser && (
        <div className="relative flex justify-center">
          <button
            ref={userMenuTriggerRef}
            className="flex items-center rounded-lg"
            onClick={() =>
              setIsUserMenuOpen((prevIsUserMenuOpen) => !prevIsUserMenuOpen)
            }
            type="button">
            <ProfileImage
              size={34}
              userId={authUser.id}
            />
          </button>
          {isUserMenuOpen && (
            <div
              ref={userMenuRef}
              className="z-10 absolute top-7 w-[200px] -right-3 rounded-lg mt-2 bg-white border border-neutral-200 drop-shadow-md">
              <ul>
                <li className="p-2 border-b-[1px] border-neutral-200 flex justify-center hover:bg-gray-100 rounded-t-lg">
                  <button
                    className="text-sm w-full"
                    onClick={() => {
                      setIsUserMenuOpen(false);
                      navigate({
                        path: SharedRoute.MY_ACCOUNT,
                        queryParams: {
                          tab: "Profile",
                        },
                      });
                    }}>
                    My Account
                  </button>
                </li>
                <li className="p-2 flex justify-center hover:bg-gray-100 rounded-b-lg">
                  <button
                    onClick={logout}
                    className="text-sm w-full">
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
