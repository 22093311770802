import { useActiveOrgRole } from "backend/resources/orgRole";
import { useShowWelcomeMessage } from "backend/resources/userOnboarding";
import { useActiveUserRole } from "backend/resources/userRole";
import { useAuthUser } from "features/users/auth";
import {
  CraniometrixProduct,
  useHasPlanBinderControl,
  useProductAccess,
} from "hooks/product/product";
import { useUserOnboarding } from "hooks/useUserOnboarding";
import { ProductFruits } from "react-product-fruits";
import { shouldIncludeProductFruitAndHotjar } from "utils";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export default function ProductFruitsWrapper() {
  const { authUser } = useAuthUser();
  const { shouldShowWelcomeMessage, isLoading } = useShowWelcomeMessage(
    authUser?.id
  );
  const {
    isUserRoleSelectionShowing,
    isCareCentralOnboardingShowing,
    isCarePilotOnboardingShowing,
  } = useUserOnboarding();
  const { userRole } = useActiveUserRole();
  const { data: orgRole } = useActiveOrgRole();
  const { data: hasCarePlanControl } = useHasPlanBinderControl();
  const { data: productAccess } = useProductAccess();
  // chatgpt sidebar state
  const { pseudoUserId } = usePseudoUserStore();

  if (
    shouldIncludeProductFruitAndHotjar() &&
    authUser &&
    !shouldShowWelcomeMessage &&
    !isLoading &&
    !isUserRoleSelectionShowing &&
    !isCareCentralOnboardingShowing &&
    !isCarePilotOnboardingShowing
  ) {
    return (
      <ProductFruits
        workspaceCode={import.meta.env.VITE_PRODUCT_FRUIT}
        language={"en"}
        user={{
          username: pseudoUserId,
          role:
            productAccess === CraniometrixProduct.CARE_CENTRAL
              ? orgRole?.role
              : userRole?.role,
          props: {
            hasDashboardAccess: (hasCarePlanControl ?? false).toString(),
          },
        }}
        lifeCycle="unmount"
      />
    );
  } else {
  }
}

interface PseudoUserStore {
  pseudoUserId: string;
}
// TODO: move to table
export const usePseudoUserStore = create<PseudoUserStore>()(
  persist(
    (set) => ({
      pseudoUserId: uuidv4(),
    }),
    {
      name: "pseudoUserStore",
    }
  )
);
