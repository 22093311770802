import { EditTeamMemberPage } from "components/AdminPage/EditTeamMemberPage";
import { InvitationPage } from "components/AdminPage/InvitationPage";
import { CookieUse } from "components/CookieUse/cookieUse";
import { HomePage } from "components/HomePage";
import PatientSearch from "components/HomePage/SuperSuperUserHome/PatientSearch";
import MFA from "components/MFA/MFA";
import { PrivacyPolicyPage } from "components/PrivacyPolicyPage";
import ServicePage from "components/ServicePage/ServicePage";
import ServiceProviderPage from "components/ServiceProviderPage.tsx/ServiceProviderPage";
import CreateOrEditServiceRequestPage from "components/ServiceRequest/CreateOrEditServiceRequestPage";
import ViewServiceRequestPage from "components/ServiceRequest/ViewServiceRequestPage";
import { TermsOfServicePage } from "components/TermsOfServicePage";
import { SharedRoute, SuperSuperUserRoute } from "lib/routing";
import "react-loading-skeleton/dist/skeleton.css";
import type { RoutLayoutObject } from "routes";
import { RouteLayout } from "routes";
import { HomeRedirect } from "routes/HomeRedirect";

import AcceptInvitation from "features/users/pages/accept-invitation";
import Login from "features/users/pages/login";
import ResetPassword from "features/users/pages/reset-password";
import UpdatePassword from "features/users/pages/update-password";

export const sharedRoutes: RoutLayoutObject[] = [
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_PROVIDER_PAGE,
    element: <ServiceProviderPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_PAGE,
    element: <ServicePage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_NEW,
    element: <CreateOrEditServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_VIEW,
    element: <ViewServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_EDIT,
    element: <CreateOrEditServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.VIEW_INVITATION,
    element: <InvitationPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.TEAM_EDIT_TEAM_MEMBER,
    element: <EditTeamMemberPage />,
  },
  {
    routeLayout: RouteLayout.PUBLIC,
    path: SharedRoute.ROOT,
    element: <Login />,
  },
  {
    routeLayout: RouteLayout.PUBLIC,
    path: SharedRoute.LOGIN,
    element: <Login />,
  },
  {
    routeLayout: RouteLayout.PUBLIC,
    path: SharedRoute.ACCEPT_INVITATION,
    element: <AcceptInvitation />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.HOME,
    element: <HomePage />,
  },
  {
    path: SharedRoute.TERMS_OF_SERVICE,
    element: <TermsOfServicePage />,
  },
  {
    path: SharedRoute.COOKIE_USE,
    element: <CookieUse />,
  },
  {
    path: SharedRoute.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
  },
  {
    routeLayout: RouteLayout.PUBLIC,
    path: SharedRoute.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    routeLayout: RouteLayout.RESTRICTED,
    path: SharedRoute.UPDATE_PASSWORD,
    element: <UpdatePassword />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.PROTECTED_ROUTE,
    path: SharedRoute.WILDCARD,
    element: <HomeRedirect />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SuperSuperUserRoute.PATIENT_SEARCH,
    element: <PatientSearch />,
  },
  {
    path: SharedRoute.MFA,
    element: <MFA />,
  },
];
