import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { useActiveNetwork } from "backend/resources/network/network";

import { useAuthUser } from "features/users/auth";
import type { User } from "features/users/types";

import {
  NetworkRoleType,
  NetworkRoleTypeToLabel,
  OrgRoleType,
  OrgRoleTypeToLabel,
} from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";

import { checkIsSuperSuperUser } from "lib";
import { useActiveOrganizationIds } from "state/organization/organization";
import type { Database } from "../../../../types/supabase";

const TABLE = "organization_role";

export type Organization = Database["public"]["Tables"]["organization"]["Row"];
export type OrganizationRole =
  Database["public"]["Tables"]["organization_role"]["Row"];
export type OrganizationRoleInsert =
  Database["public"]["Tables"]["organization_role"]["Insert"];
export type OrganizationRoleUpdate =
  Database["public"]["Tables"]["organization_role"]["Update"];

export type OrgAndIdentityReturnType = OrganizationRole & {
  organization: Organization | null; // asserts 1-to-1 relationship in join type to avoid [] || {} || null union type, known supabase bug
  user: User;
};

export function getRoleLabel(role: any): string {
  if (Object.values(OrgRoleType).includes(role)) {
    return OrgRoleTypeToLabel[role as OrgRoleType];
  } else if (Object.values(NetworkRoleType).includes(role)) {
    return NetworkRoleTypeToLabel[role as NetworkRoleType];
  } else {
    return role;
  }
}

/**
 * Query functions
 */
async function fetchAllOrgAndIdentities() {
  const { data, error } = await supabase
    .from(TABLE)
    .select("*, organization(*), user(*)");

  if (error) {
    Sentry.captureException(error);
  }

  return data as OrgAndIdentityReturnType[];
}

async function fetchOrgById(organizationId?: string | null) {
  if (!organizationId) return null;

  const { data, error } = await supabase
    .from("organization")
    .select("*")
    .eq("id", organizationId)
    .limit(1)
    .maybeSingle();

  // .eq("user_id", userId)
  // without below returns method, there's a bug in return type
  // regardless of unique constraints, returns `[] | {} | null` type
  // this has been fixed in postgrest but not supabase.
  // https://github.com/orgs/supabase/discussions/7610#discussioncomment-5515519
  // .returns<OrgAndIdentityReturnType[]>(); // asserts one-to-one relationship

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}

async function fetchOrgRole(organization_id?: string, user_id?: string) {
  if (!organization_id || !user_id) return null;

  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("user_id", user_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return data;
}

export async function fetchUserFromOrgRoleId(
  orgRoleId: string | undefined | null,
  organizationIds: string[] | undefined
) {
  if (!orgRoleId || !organizationIds) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("id", orgRoleId)
    .in("organization_id", organizationIds)
    .returns<(OrganizationRole & { user: User })[]>()
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw error;
  }

  return data;
}

/**
 * Query hooks
 */

export const useUserFromOrgRoleId = (org_role_id?: string | null) => {
  const activeOrgIds = useActiveOrganizationIds();
  return useQuery({
    queryKey: [QUERY_KEYS.orgAndIdentities, { org_role_id, activeOrgIds }],
    queryFn: () => fetchUserFromOrgRoleId(org_role_id, activeOrgIds),
    refetchOnWindowFocus: false,
  });
};

export function useOrgs() {
  const { authUser } = useAuthUser();

  const { data: allOrgIdentities, isLoading: isAllOrgIdentitesLoading } =
    useQuery({
      queryKey: [
        QUERY_KEYS.orgAndIdentities,
        { authUser: authUser?.id, productAccessQuery: true },
      ],
      queryFn: () => fetchAllOrgAndIdentities(),
      refetchOnWindowFocus: false,
      enabled: !!authUser,
    });

  const isSuperSuperUser = checkIsSuperSuperUser(
    authUser?.id,
    allOrgIdentities
  );

  // We always let users see the super org... but here we don't want to show those.
  const ownOrgIdentities = isSuperSuperUser
    ? allOrgIdentities
    : allOrgIdentities?.filter(
        (orgIdentity) => orgIdentity.organization?.is_super_org === false
      );

  // Check if user has admin access in any org
  const hasAdminAccess =
    isSuperSuperUser ||
    ownOrgIdentities?.some((orgIdentity) => orgIdentity.is_superuser);

  const hasOneOrMoreOrgIdentities =
    ownOrgIdentities && ownOrgIdentities.length > 0;

  const { data: network } = useActiveNetwork();
  const firstOrganizationId =
    ownOrgIdentities?.[0]?.organization?.id ?? network?.organization_id;

  // Get deduped ownOrgs
  const ownOrganizationsMap: Record<string, Organization> = {};
  if (ownOrgIdentities)
    for (const orgIdentity of ownOrgIdentities) {
      if (orgIdentity.organization) {
        ownOrganizationsMap[orgIdentity.organization_id] =
          orgIdentity.organization;
      }
    }
  const ownOrganizations = Object.values(ownOrganizationsMap);
  const hasCareCentralAccess = ownOrganizations.length > 0;

  return {
    isLoading: isAllOrgIdentitesLoading,
    allOrgIdentities, // returns all orgs the member is part of, plus super orgs (RLS policy)
    ownOrgIdentities, // if not super user, filter out super orgs
    ownOrganizations, // own organizations (no)
    hasOneOrMoreOrgIdentities,
    firstOrganizationId,
    isSuperSuperUser,
    hasCareCentralAccess,
    hasAdminAccess,
  };
}

export function useOrg(organizationId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.orgs, { organizationId }],
    queryFn: () => fetchOrgById(organizationId),
    refetchOnWindowFocus: false,
  });
}

export function useActiveOrg() {
  const activeOrgIds = useActiveOrganizationIds();

  return useQuery({
    queryKey: [QUERY_KEYS.activeOrg, { activeOrgIds }],
    queryFn: () => {
      if (!activeOrgIds) return null;

      if (activeOrgIds.length > 1) {
        throw new Error("Multiple active orgs not supported");
      }

      return fetchOrgById(activeOrgIds[0]);
    },
    refetchOnWindowFocus: false,
  });
}

export function useActiveOrgRole() {
  const { authUser } = useAuthUser();
  const activeOrgIds = useActiveOrganizationIds();

  return useQuery({
    queryKey: [
      QUERY_KEYS.orgAndIdentities,
      { authUser: authUser?.id, activeOrgIds },
    ],
    queryFn: () => {
      if (!activeOrgIds || !authUser) return null;

      if (activeOrgIds.length > 1) {
        throw new Error("Multiple active orgs not supported");
      }

      return fetchOrgRole(activeOrgIds[0], authUser?.id);
    },
    refetchOnWindowFocus: false,
  });
}

/**
 * Mutation functions
 */

async function upsertOrgRole(insert?: OrganizationRoleInsert) {
  // handle null case
  if (!insert) throw new Error("No org role provided to upsertOrgRole");
  //
  const { data, error } = await supabase.from(TABLE).upsert([insert]).select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

async function updateOrgRole(orgRole: OrganizationRoleUpdate) {
  if (!orgRole.id) return null;

  const { data, error } = await supabase
    .from(TABLE)
    .update(orgRole)
    .eq("id", orgRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

/**
 * Mutation hooks
 */

export function useUpsertOrgRole() {
  return useMutation({
    mutationFn: ({
      orgRoleInsert,
    }: {
      orgRoleInsert?: OrganizationRoleInsert;
    }) => upsertOrgRole(orgRoleInsert),
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
    },
  });
}

export function useUpdateOrgRole() {
  return useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isSuperUser,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: OrgRoleType;
      isSuperUser?: boolean;
      isDeactivated?: boolean;
    }) => {
      const response = await updateOrgRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
        is_superuser: isSuperUser,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMemberByInvitationId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });
}
