/**
 * @fileoverview Sets up the Zustand library organization store.
 * This store holds information about organization state.
 */
import { useEffect } from "react";
import { LocalStorageKeys } from "state/localStorageKeys";
import { create } from "state/zustand-create";
import { persist } from "zustand/middleware";
import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import { useRole } from "hooks/role/useRole";

/**
 * Hook to access the organization store
 */

type State = {
  activeOrganizationIds?: string[];
  organizationIds?: string[];
};

type Actions = {
  setActiveOrganizationIds: (activeOrganizationIds: string[]) => void;
  setOrganizationIds: (organizationIds: string[]) => void;
  reset: () => void;
};

const initialState: State = {
  activeOrganizationIds: undefined,
  organizationIds: [],
};

// TODO: remove this as a persistent store, it's a potential HIPAA violation. Removal might cause bugs.
// See https://app.shortcut.com/craniometrix/story/3097/frontend-remove-persist-from-organization-store
export const useOrganizationStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setActiveOrganizationIds: (activeOrganizationIds) =>
        set({ activeOrganizationIds }),
      setOrganizationIds: (organizationIds) => set({ organizationIds }),
      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.organization, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export function useActiveOrganizationIds() {
  const { activeOrganizationIds, setActiveOrganizationIds } =
    useOrganizationStore();
  const { data: organizations, isLoading: isLoadingOrganizations } =
    useOrganizations({
      equals: { is_super_org: false },
    });
  const { isSuperSuperUser, isLoading: isLoadingRole } = useRole();
  const organizationIds = organizations?.map((org) => org.id);

  useEffect(() => {
    if (isLoadingRole || isLoadingOrganizations) {
      return;
    }

    if (!organizationIds || organizationIds.length === 0) {
      return;
    }

    if (activeOrganizationIds && activeOrganizationIds.length > 0) {
      return;
    }

    if (isSuperSuperUser) {
      setActiveOrganizationIds(organizationIds);
      return;
    }

    // If the user is not a super super user, set the active organization to the first organization
    setActiveOrganizationIds([organizationIds[0]]);
  }, [
    organizationIds,
    activeOrganizationIds,
    setActiveOrganizationIds,
    isSuperSuperUser,
    isLoadingRole,
    isLoadingOrganizations,
  ]);

  return activeOrganizationIds;
}
