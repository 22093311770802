import { useEffect, type ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { SharedRoute } from "lib/routing";

import { useAuthListener, useAuthUser } from "features/users/auth";

import { Header } from "./components/header";

export interface PublicLayoutProps {
  children: ReactNode;
}

export default function PublicLayout({ children }: PublicLayoutProps) {
  useAuthListener();
  const navigate = useNavigate();
  const { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser) {
      navigate(SharedRoute.HOME);
    }
  }, [authUser, navigate]);

  return (
    <section className="flex flex-col bg-neutral-50 h-full w-full overflow-y-scroll">
      <Header />
      <main className="flex-grow p-4">{children}</main>
    </section>
  );
}
