import useIntersectionObserver from "@react-hook/intersection-observer";
import { useEffect, useRef } from "react";
import invariant from "tiny-invariant";

import { YouTubeVideo } from "components/YouTubeVideo";
import { useAuthUser } from "features/users/auth";

interface Props {
  id: string;
  uniqueId: string;
  src: string;
  slug: string;
  title: string;
  description: string | null;
  isFirst: boolean;
  isLast: boolean;
  setShowRightArrow: (showRightArrow: boolean) => void;
  setShowLeftArrow: (showRightArrow: boolean) => void;
}

export function VideoDisplay({
  id,
  uniqueId,
  src,
  title,
  slug,
  description,
  isFirst,
  isLast,
  setShowLeftArrow,
  setShowRightArrow,
}: Props) {
  const ref = useRef(null);
  const { authUser } = useAuthUser();
  // TODO(jen): put this everywhere, document null not strict inequality so covers undefined
  invariant(authUser != null, "User should not be undefined");
  const { isIntersecting } = useIntersectionObserver(ref, { threshold: 1 });

  useEffect(() => {
    if (isFirst) {
      if (isIntersecting) {
        setShowLeftArrow(false);
      } else {
        setShowLeftArrow(true);
      }
    }

    if (isLast) {
      if (isIntersecting) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    }
  }, [isIntersecting, isFirst, isLast]);

  return (
    <div
      className="flex flex-col gap-4 max-w-[302px] w-full"
      ref={ref}
      id={uniqueId}>
      <div className="w-[302px] h-[170px] flex-shrink-0">
        <YouTubeVideo
          url={src}
          slug={slug}
        />
      </div>
      <div className="flex flex-col items-start px-2">
        <div className="self-stretch flex items-start justify-between flex-nowrap">
          <p className="self-start font-medium mb-1 line-clamp-1">{title}</p>
        </div>
        {description && (
          <p
            title={description}
            className="text-xs text-gray-600 line-clamp-3">
            {description}
          </p>
        )}
      </div>
    </div>
  );
}
