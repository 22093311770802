import {
  TaskStatus,
  TaskStatusLabel,
  useAllPlanEntriesByCarespaces,
  useBaseAllPlanEntries,
} from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { TasksByCarespaceTable } from "components/Tables/TasksByCarespaceTable/TasksByCarespaceTable";
import type { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import {
  TaskFilters,
  TaskFilterTypes,
} from "components/TaskNavigatorPage/TaskFilters";
import { TaskHighPieChart } from "components/TaskNavigatorPage/TaskHighPieChart";
import { generateTitleHeader } from "components/TaskNavigatorPage/taskTableUtils";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

import { Combobox } from "shared/ui/combobox";
import { LabeledContent } from "shared/ui/labeled-content";

export default function TaskNavigatorPage() {
  const navigate = useAppNavigate();
  const { setStatus, status, hideDone, setHideDone } = useTaskFilterStore();
  const filterConfig: FilterConfig = {
    [TaskFilterTypes.CARESPACE]: true,
    [TaskFilterTypes.CATEGORY]: true,
    [TaskFilterTypes.WHO]: true,
    [TaskFilterTypes.DUE_DATE]: true,
    [TaskFilterTypes.STATUS]: true,
    [TaskFilterTypes.HIDE_DONE]: true,
    [TaskFilterTypes.TASK_TYPE]: true,
    [TaskFilterTypes.GUIDE_TASK]: true,
  };
  const { data: tasks, isLoading: isLoadingTasks } =
    useAllPlanEntriesByCarespaces(filterConfig);
  const { data: baseEntries, isLoading: isLoadingDonutData } =
    useBaseAllPlanEntries(filterConfig);

  return (
    <div className="flex flex-col gap-4 pb-20">
      <h1 className="text-xl">
        {generateTitleHeader("GUIDE Task Status", baseEntries ?? [])}
      </h1>
      <div className="flex flex-col xl:flex-row gap-4 w-[1400px]">
        <div className="w-full xl:w-1/3">
          <TaskHighPieChart
            data={baseEntries ?? []}
            isLoading={isLoadingDonutData}
          />
        </div>
        <div className="xl:block w-full xl:w-2/3">
          <TaskFilters
            filterConfig={{
              [TaskFilterTypes.CARESPACE]: true,
              [TaskFilterTypes.CATEGORY]: true,
              [TaskFilterTypes.WHO]: true,
              [TaskFilterTypes.DUE_DATE]: true,
              [TaskFilterTypes.TASK_TYPE]: true,
              [TaskFilterTypes.GUIDE_TASK]: true,
            }}
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-4 items-center">
        <div className="flex flex-wrap gap-4 items-center">
          <LabeledContent
            label="Status"
            orientation="horizontal">
            <Combobox
              options={[
                { value: "All", label: "All" },
                ...Object.values(TaskStatus).map((status) => ({
                  label: TaskStatusLabel[status],
                  value: status,
                })),
                { value: "Overdue", label: "Overdue" },
              ]}
              value={status}
              onChange={(status) => setStatus(status)}
            />
          </LabeledContent>

          {status === "All" && (
            <div className="flex gap-2 items-center">
              <DefaultCheckbox
                checked={hideDone}
                onChange={(isChecked) => setHideDone(isChecked)}
              />
              <p className="text-sm">Hide Done</p>
            </div>
          )}

          <ButtonWithIcon
            icon={IconOption.PLUS}
            onClick={() => navigate({ path: CareCentralRoute.NEW_TASK })}
            text="Add General Task"
          />
        </div>
      </div>

      {(tasks?.length ?? 0) > 0 || isLoadingTasks ? (
        <TasksByCarespaceTable rawData={tasks ?? []} />
      ) : (
        <p className="text-sm">
          Currently, there are no tasks available. To create the necessary tasks
          list, please add a carespace from the
          <a
            className="text-brand-orange cursor-pointer"
            onClick={() => navigate({ path: CareCentralRoute.CARESPACES })}>
            {" "}
            carespace page
          </a>
          .
        </p>
      )}
    </div>
  );
}
