import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { supabase } from "clients/supabaseClient";

import { SharedRoute } from "lib/routing";

import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";

import { useToast } from "shared/hooks/use-toast";

const formSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
  password: z.string().min(1, { message: "Password is required" }),
});

type FormSchema = z.infer<typeof formSchema>;

export default function Login() {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { toast } = useToast();

  const onSubmit = async (data: FormSchema) => {
    const { error } = await supabase.auth.signInWithPassword(data);

    if (error) {
      toast({
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card
        title="Welcome to Craniometrix"
        className="w-[450px]">
        <Form
          form={form}
          onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            label="Email"
            render={({ field }) => <Input {...field} />}
          />

          <div className="flex flex-col gap-1">
            <FormField
              control={form.control}
              name="password"
              label="Password"
              render={({ field }) => (
                <Input
                  type="password"
                  {...field}
                />
              )}
            />

            <Link
              className="text-brand-orange hover:brightness-110 transition-colors text-sm ml-auto"
              to={SharedRoute.RESET_PASSWORD}>
              Forgot password?
            </Link>
          </div>

          <Button
            type="submit"
            className="w-full"
            isLoading={form.formState.isSubmitting}>
            Log In
          </Button>
        </Form>
      </Card>
    </div>
  );
}
