import { useEffect, type ReactNode } from "react";

import { LoadingSpinner } from "components/LoadingSpinner";

import { useAuthListener, useAuthUser } from "features/users/auth";

import { Button } from "shared/ui/button";

import { useToast } from "shared/hooks/use-toast";

import { Header } from "./components/header";

export interface RestrictedLayoutProps {
  children: ReactNode;
}

export default function RestrictedLayout({ children }: RestrictedLayoutProps) {
  useAuthListener();
  const { authUser, logout } = useAuthUser();
  const { toast } = useToast();

  useEffect(() => {
    const id = setTimeout(() => {
      toast({
        title: "This is taking a while",
        description: (
          <div>
            Something may have gone wrong.{" "}
            <Button
              variant="link"
              size="auto"
              onClick={logout}>
              Click here
            </Button>{" "}
            to try logging in again.
          </div>
        ),
        variant: "destructive",
      });
    }, 5000);

    if (authUser) {
      clearTimeout(id);
    }

    return () => clearTimeout(id);
  }, [authUser, logout, toast]);

  return (
    <section className="flex flex-col bg-neutral-50 h-full w-full overflow-y-scroll">
      <Header />

      <main className="flex-grow p-4">
        {authUser ? children : <LoadingSpinner className="w-6 h-6" />}
      </main>
    </section>
  );
}
