import { useMutation } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { supabase } from "clients/supabaseClient";
import type { Database } from "../../../../types/supabase";

export type Organization = Database["public"]["Tables"]["organization"]["Row"];
export type OrganizationInsert =
  Database["public"]["Tables"]["organization"]["Insert"];
export type OrganizationUpdate =
  Database["public"]["Tables"]["organization"]["Update"];

const TABLE = "organization";

async function updateOrg(organizationUpdate: OrganizationUpdate) {
  if (!organizationUpdate || !organizationUpdate.id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .update(organizationUpdate)
    .eq("id", organizationUpdate.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useUpdateOrg() {
  return useMutation({
    mutationFn: async (organizationUpdate: OrganizationUpdate) => {
      const response = await updateOrg(organizationUpdate);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgs],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.activeOrg],
      });
    },
  });
}
