import type { Cell, Row } from "@tanstack/react-table";
import type {
  PlanEntryUpdate,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";
import { ServiceStatus, TaskStatus } from "backend/resources/planEntry";
import type { PaginationOptions } from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { TaskTableCellContent } from "components/Tables/TaskTable/TaskTableCellContent";
import type { TaskTableType } from "components/Tables/TaskTable/TaskTableTypes";
import {
  TaskTableFields,
  TaskTableRowType,
  getTaskType,
} from "components/Tables/TaskTable/TaskTableTypes";
import type { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import { useFilteredData } from "components/TaskNavigatorPage/taskTableUtils";
import { useAuthUser } from "features/users/auth";
import { useWindowSize } from "hooks/useWindowSize";
import {
  CareCentralRoute,
  CarePilotRoute,
  SharedRoute,
  useAppNavigate,
} from "lib/routing";
import { useEffect, useState } from "react";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

interface TaskTableProps {
  filterConfig?: FilterConfig;
  sortFunction?: (a: TaskTableType, b: TaskTableType) => number;
  data: TaskWithGuideInfo[] | undefined;
  hiddenColumns?: string[];
  updateTask: (taskUpdate: PlanEntryUpdate) => void;
  shouldPreventClicks?: boolean;
  handleClick?(
    cell: Cell<TaskTableType, unknown>,
    row: Row<TaskTableType>
  ): void;
  hideCheckbox?: boolean;
  overriddenHeaderNames?: { [key: string]: string };
  paginationOptions?: PaginationOptions<TaskTableType>;
  maxHeight?: string;
  isLoading?: boolean;
}

export function TaskTable({
  shouldPreventClicks,
  filterConfig,
  sortFunction,
  hiddenColumns,
  data: rawData,
  updateTask,
  handleClick,
  isLoading,
  hideCheckbox,
  overriddenHeaderNames,
  maxHeight,
  paginationOptions,
}: TaskTableProps) {
  const navigate = useAppNavigate();

  const [data, setData] = useState<TaskTableType[]>(() => []);
  const {
    carespace: network,
    category,
    who,
    status,
    dueDate,
    entryId,
    setEntryId,
    hideDone,
  } = useTaskFilterStore();
  const { authUser } = useAuthUser();
  const { isMobile } = useWindowSize();
  const filteredData = useFilteredData({
    data: rawData,
    config: filterConfig,
  });
  // ///////
  // Effects
  /// /////

  useEffect(() => {
    // Updates data
    if (filteredData) {
      const data = filteredData.map((item) => {
        const hasDocumentAttached: boolean =
          (item.chat_gpt_conversation?.conversation_document?.length ?? 0) > 0;
        return {
          [TaskTableFields.Id]: item.id,
          [TaskTableFields.CreatedAt]: new Date(
            item.created_at
          ).toLocaleDateString("en-US"),
          [TaskTableFields.TaskName]: item.name,
          [TaskTableFields.Carespace]: item.network?.name ?? "",
          [TaskTableFields.Category]:
            item.guide_task?.guide_category?.short_title ?? "N/A",
          [TaskTableFields.CategoryFullTitle]:
            item.guide_task?.guide_category?.title ?? "N/A",
          [TaskTableFields.Account]: "All",
          [TaskTableFields.Due]: new Date(
            item.scheduled_date_time ?? ""
          ).toLocaleDateString("en-US"),
          [TaskTableFields.Who]:
            item.plan_entry_attendee?.find(
              (attendee) => attendee.user_id === authUser?.id
            )?.user_id ?? item.user_id,
          [TaskTableFields.Status]: item.status as TaskStatus,
          [TaskTableFields.NetworkId]: item.network_id ?? "",
          [TaskTableFields.RecurringInterval]:
            item.recurring_interval as string,
          [TaskTableFields.ServiceStatus]: item.service_request_to_engagement
            ?.service_engagement
            ? item.service_request_to_engagement?.service_engagement.is_active
              ? ServiceStatus.Active
              : ServiceStatus.Inactive
            : ServiceStatus.NotApplicable,
          [TaskTableFields.TaskType]: getTaskType(item),
          [TaskTableFields.RawData]: item,
          [TaskTableFields.GUIDEIdentifier]:
            item.guide_task?.GUIDE_identifier ?? "",
          [TaskTableFields.HasDocumentAttached]:
            (item.chat_gpt_conversation?.conversation_document?.length ?? 0) >
            0,
          subRows: item.subRows?.map((subRow) => ({
            [TaskTableFields.Id]: subRow.id,
            [TaskTableFields.RawData]: subRow,
            [TaskTableFields.CreatedAt]: new Date(
              subRow.created_at
            ).toLocaleDateString("en-US"),
            [TaskTableFields.TaskName]: subRow.name,
            [TaskTableFields.Carespace]: subRow.network?.name ?? "",
            [TaskTableFields.TaskType]: getTaskType(subRow),
            [TaskTableFields.Category]: "",
            [TaskTableFields.CategoryFullTitle]: "",
            [TaskTableFields.Account]: "All",
            [TaskTableFields.Due]: new Date(
              subRow.scheduled_date_time ?? ""
            ).toLocaleDateString("en-US"),
            [TaskTableFields.Who]: subRow.user_id,
            [TaskTableFields.Status]: subRow.status as TaskStatus,
            [TaskTableFields.NetworkId]: subRow.network_id ?? "",
            [TaskTableFields.RecurringInterval]:
              subRow.recurring_interval as string,
            [TaskTableFields.ServiceStatus]: ServiceStatus.NotApplicable,
            [TaskTableFields.GUIDEIdentifier]: "",
            [TaskTableFields.HasDocumentAttached]: hasDocumentAttached,
            subRows: [],
          })),
        };
      });

      const sortedData: TaskTableType[] = sortFunction
        ? data.sort(sortFunction)
        : data;
      setData(sortedData);
    }
  }, [filteredData, network, who, category, status, dueDate, hideDone]);

  // ///////
  // Handlers
  /// /////

  function defaultHandleClick(
    cell: Cell<TaskTableType, unknown>,
    row: Row<TaskTableType>
  ) {
    if (shouldPreventClicks) return;
    switch (cell.column.id) {
      case TaskTableFields.Category: {
        navigate({
          path: CareCentralRoute.GUIDE_CATEGORY,
          params: {
            category: encodeURIComponent(
              row.original[TaskTableFields.CategoryFullTitle] ?? "N/A"
            ),
            network_id: row.original[TaskTableFields.NetworkId],
          },
        });
        break;
      }
      default: {
        setEntryId(row.original[TaskTableFields.Id]);
        switch (row.original[TaskTableFields.TaskType]) {
          case TaskTableRowType.ONBOARDING: {
            navigate({
              path: CareCentralRoute.VIEW_TASK,
              params: {
                id: row.original[TaskTableFields.Id],
              },
            });
            break;
          }
          case TaskTableRowType.GENERAL: {
            navigate({
              path: CarePilotRoute.PLAN_ID,
              params: {
                id: row.original[TaskTableFields.Id],
              },
            });
            break;
          }
          case TaskTableRowType.SERVICE_REQUEST: {
            navigate({
              path: SharedRoute.SERVICE_REQUEST_VIEW,
              params: {
                id: row.original[TaskTableFields.Id],
              },
            });
            break;
          }
        }
        break;
      }
    }
  }

  return (
    <InfiniteScrollingTable
      data={data}
      isLoading={isLoading}
      paginationOptions={paginationOptions}
      maxHeight={maxHeight}
      headersToCenter={[
        TaskTableFields.Status,
        TaskTableFields.TaskType,
        TaskTableFields.Category,
        TaskTableFields.ServiceStatus,
      ]}
      checkboxOptions={
        !hideCheckbox
          ? {
              disabled: (row: Row<TaskTableType>) =>
                row.original[TaskTableFields.Status] === TaskStatus.Done &&
                !!row.original[TaskTableFields.RecurringInterval],
              onCheck: (row: Row<TaskTableType>, isChecked: boolean) => {
                const newStatus = isChecked
                  ? TaskStatus.Done
                  : TaskStatus.InProgress;
                updateTask({
                  id: row.original[TaskTableFields.Id],
                  status: newStatus,
                });
              },
              isChecked: (row: Row<TaskTableType>) =>
                row.original[TaskTableFields.Status] === TaskStatus.Done,
            }
          : undefined
      }
      mobileColumns={[TaskTableFields.TaskName, TaskTableFields.Due]}
      hiddenColumns={[
        ...(hiddenColumns ?? []),
        TaskTableFields.NetworkId,
        TaskTableFields.CategoryFullTitle,
        TaskTableFields.Id,
        TaskTableFields.Account,
        TaskTableFields.RawData,
        TaskTableFields.GUIDEIdentifier,
        TaskTableFields.HasDocumentAttached,
        "subRows",
      ]}
      hiddenColumnHeaders={[TaskTableFields.RecurringInterval]}
      CellContent={({ cell, row }) => (
        <TaskTableCellContent
          cell={cell}
          row={row}
        />
      )}
      columnFields={Object.values(TaskTableFields)}
      overriddenHeaderNames={overriddenHeaderNames}
      handleClick={handleClick ?? defaultHandleClick}
    />
  );
}
