import * as Sentry from "@sentry/react";
import { useOrg } from "backend/resources/orgRole";
import { useOrganizationInvitationByOrganizationInvitationId } from "backend/resources/organizationInvitations/organizationInvitation";
import AnalyticsPage from "components/AnalyticsPage/AnalyticsPage";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import Tabs from "components/Tabs/Tabs";
import TaskNavigatorPage from "components/TaskNavigatorPage/TaskNavigatorPage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useOrganizationStore } from "state/organization/organization";

const TITLE = "My Dashboard";

export enum CareCentralHomeTabs {
  TASK_NAVIGATOR = "Task Navigator",
  ANALYTICS = "Analytics",
}

export default function CareCentralHome() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const invitationId = queryParams.get("invitation-id");
  const isAcceptedInvitation =
    queryParams.get("accepted-invitation") === "true";

  // state
  const [hasAcceptedOrganizationInvite, setHasAcceptedOrganizationInvite] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(
    CareCentralHomeTabs.TASK_NAVIGATOR
  );

  // queries
  const { data: acceptedInvitation } =
    useOrganizationInvitationByOrganizationInvitationId(invitationId);
  const { data: newlyAddedOrganization } = useOrg(
    acceptedInvitation?.organization_id
  );
  const flags = useFlags();

  // effects
  useEffect(() => {
    if (isAcceptedInvitation && newlyAddedOrganization) {
      setHasAcceptedOrganizationInvite(true);
    }
  }, [newlyAddedOrganization, isAcceptedInvitation]);

  return (
    <PageContainer height="h-[90%] md:h-[100%]">
      <div className="flex flex-col gap-4">
        <PageMainHeader text={TITLE} />
        {/* Only show Analytics tab if the flag is enabled */}
        {flags.trends_analytics_sub_page && (
          <Tabs
            tabs={Object.values(CareCentralHomeTabs)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        )}
        {currentTab === CareCentralHomeTabs.TASK_NAVIGATOR && (
          <TaskNavigatorPage />
        )}
        {currentTab === CareCentralHomeTabs.ANALYTICS && <AnalyticsPage />}
        <OrganizationAcceptanceModal
          organizationName={newlyAddedOrganization?.name}
          organizationId={newlyAddedOrganization?.id}
          hasAcceptedOrganizationInvite={hasAcceptedOrganizationInvite}
          setHasAcceptedOrganizationInvite={setHasAcceptedOrganizationInvite}
        />
      </div>
    </PageContainer>
  );
}

function OrganizationAcceptanceModal({
  organizationName,
  organizationId,
  hasAcceptedOrganizationInvite,
  setHasAcceptedOrganizationInvite,
}: {
  organizationName: string | undefined | null;
  organizationId: string | undefined | null;
  hasAcceptedOrganizationInvite: boolean;
  setHasAcceptedOrganizationInvite: (value: boolean) => void;
}) {
  // stores
  const setActiveOrganizationIds = useOrganizationStore(
    (state) => state.setActiveOrganizationIds
  );

  if (!hasAcceptedOrganizationInvite) {
    return null;
  }

  if (!organizationName || !organizationId) {
    Sentry.captureException(new Error("Organization name or id is missing"));
    return null;
  }

  return (
    <ResponsiveModal
      title={`You've been added to the ${organizationName} organization!`}
      isOpen={true}
      onClose={() => {
        setHasAcceptedOrganizationInvite(false);
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={() => {
              setHasAcceptedOrganizationInvite(false);
            }}
            text={"Close"}
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              setActiveOrganizationIds([organizationId]);
              setHasAcceptedOrganizationInvite(false);
            }}
            text={`Go to ${organizationName}`}
            icon={IconOption.ARROW}
          />
        </>
      }>
      <p>
        You can switch organizations by clicking on Profile Menu on the top
        right and then "Switch Organization"
      </p>
    </ResponsiveModal>
  );
}
