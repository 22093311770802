import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import type { Database } from "../../../../types/supabase";

const TABLE = "notification_preferences";

export enum NotificationSection {
  GENERAL = "General",
  MY_PLAN = "My Plan",
  CARE_PLAN = "Care Plan",
  FAMILY_HUB = "Family Hub",
}

export enum NotificationChannel {
  SMS = "sms",
  EMAIL = "email",
  PUSH = "push",
}

export type NotificationPreference =
  Database["public"]["Tables"]["notification_preferences"]["Row"];

export type NotificationPreferenceUpsert =
  Database["public"]["Tables"]["notification_preferences"]["Insert"];

export const useNotificationPreferences = () => {
  const { authUser } = useAuthUser();

  const {
    isLoading: isLoadingPreferences,
    data: notificationPreferences,
    error: preferencesError,
    refetch: refetchPreferences,
  } = useQuery({
    queryKey: [QUERY_KEYS.notificationPreferences, { userId: authUser?.id }],
    queryFn: () => fetchAllPreferencesForUser(authUser?.id),
  });

  return {
    isLoadingPreferences,
    notificationPreferences,
    preferencesError,
    refetchPreferences,
  };
};

export function useUpsertNotificationPreferenceMutation() {
  return useMutation({
    mutationFn: async (preferenceUpdate: NotificationPreferenceUpsert) => {
      const response = await upsertNotificationPreference(preferenceUpdate);
      return response.data?.[0];
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.notificationPreferences],
      });
    },
  });
}

export function useUpdateNotificationPreferenceForSectionMutation() {
  return useMutation({
    mutationFn: async ({
      user_id,
      channel,
      enabled,
    }: {
      user_id: string;
      channel: NotificationChannel;
      enabled: boolean;
    }) => {
      const { data, error } = await supabase
        .from(TABLE)
        .update({ [channel]: enabled })
        .eq("user_id", user_id)
        .select();
      if (error) {
        Sentry.captureException(error);
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.notificationPreferences],
      });
    },
  });
}

export async function fetchAllPreferencesForUser(userId?: string) {
  if (!userId) return [];
  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("user_id", userId);
  if (error) {
    Sentry.captureException(error);
  }
  return data;
}

export async function upsertNotificationPreference(
  preferenceUpdate: NotificationPreferenceUpsert
) {
  const { data, error } = await supabase
    .from(TABLE)
    .upsert(preferenceUpdate, { onConflict: "user_id, section_name" })
    .select();

  if (error) {
    Sentry.captureException(error);
  }
  return { data };
}
