/**
 * @fileoverview ProtectedRoute component.
 */

import { useIsNetworkAllowed } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";
import {
  useActiveUserRole,
  useUserIsFamilyMember,
} from "backend/resources/userRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useAuthListener, useAuthUser } from "features/users/auth";
import { CarePilotRoute, SharedRoute, useAppNavigate } from "lib/routing";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LogoutReasonType, useLogoutReasonStore } from "state/logout/logout";
import { useNetworkStore } from "state/network/network";

interface Props {
  component: JSX.Element;
}

/**
 * Component for protecting routes. Any Route rendered with this
 * will be protected by authentication.
 */
export function ProtectedRoute({ component }: Props): JSX.Element {
  useAuthListener();
  const { authUser, logout } = useAuthUser();
  const navigate = useAppNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlNetworkId = queryParams.get("network_id");

  // hooks
  const { userRole, isUserRoleLoading } = useActiveUserRole();
  const { userIsFamilyMember } = useUserIsFamilyMember();
  const {
    isSuperSuperUser,
    isLoading: isLoadingIsSuperSuperUser,
    hasAdminAccess,
  } = useOrgs();
  const { data: isNetworkAllowed, isLoading: isLoadingIsNetworkAllowed } =
    useIsNetworkAllowed(urlNetworkId);

  // stores
  const setLogoutReason = useLogoutReasonStore(
    (state) => state.setLogoutReason
  );
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  // effects
  useEffect(() => {
    if (urlNetworkId) {
      if (
        !isNetworkAllowed &&
        !isLoadingIsNetworkAllowed &&
        !isSuperSuperUser
      ) {
        setLogoutReason(LogoutReasonType.WRONG_LINK);
      } else {
        setActiveNetworkId(urlNetworkId);
      }
    }
  }, [
    urlNetworkId,
    isNetworkAllowed,
    isLoadingIsNetworkAllowed,
    isSuperSuperUser,
    setLogoutReason,
    setActiveNetworkId,
  ]);

  // TODO this should be a mapping.
  useEffect(() => {
    const isAdminOrSuperUser =
      hasAdminAccess ||
      (!isLoadingIsSuperSuperUser && isSuperSuperUser) ||
      (!isUserRoleLoading &&
        userRole?.role === NetworkRoleType.PRIMARY_CAREGIVER);
    if (!authUser) {
      logout();
    } else if (
      userIsFamilyMember &&
      ![
        CarePilotRoute.FAMILY_HUB,
        CarePilotRoute.ASSESSMENTS,
        SharedRoute.MY_ACCOUNT,
        CarePilotRoute.SETTINGS,
        CarePilotRoute.RECOMMENDATION,
        CarePilotRoute.INTERVENTION,
        CarePilotRoute.VIEW_ALL_RECOMMENDATION,
        CarePilotRoute.BOOKMARK,
      ]
        .map((path) => path.replace(":id", ""))
        .some((path) => location.pathname.startsWith(path))
    ) {
      // Family members can only see the updates page
      navigate({
        path: CarePilotRoute.FAMILY_HUB,
      });
    } else if (
      location.pathname.startsWith(SharedRoute.ADMIN) &&
      !isAdminOrSuperUser
    ) {
      navigate({
        path: SharedRoute.HOME,
      });
    }
  }, [
    authUser,
    userRole,
    isUserRoleLoading,
    location,
    hasAdminAccess,
    isLoadingIsSuperSuperUser,
    isSuperSuperUser,
    userIsFamilyMember,
    logout,
    navigate,
  ]);

  if (isUserRoleLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  } else {
    return component;
  }
}
