/**
 * @fileoverview Sets up the Zustand side nav store.
 * This store holds information about the side nav's collapsed / not collapsed state.
 */
import type { NetworkRoleType } from "backend/resources/userRole/types";
import type { NavItemName } from "state/navItems";
import { NavItem, determineItemsBasedOnRole } from "state/navItems";
import { create } from "zustand";

type State = {
  items: NavItem[];
  extendedItems: NavItem[];
  isMinimized: boolean;
};

type Actions = {
  expandItem: (item: NavItemName) => void;
  collapseItem: (item: NavItemName) => void;
  reset: () => void;
  setIsMinimized: (isMinimized: boolean) => void;
  resetItems: () => void;
  configureNavContext: (context: {
    role: NetworkRoleType;
    isMobile: boolean;
    isSuperOrg: boolean;
  }) => void;
};

const initialState: State = {
  items: [],
  extendedItems: [],
  isMinimized: false,
};

/**
 * Hook to access the side nav store
 */
export const useSideNavStore = create<State & Actions>((set, get) => ({
  ...initialState,
  expandItem: (itemName: NavItemName) => {
    const items = get().items.map((item) => {
      if (item.name === itemName) {
        return { ...item, isExpanded: true };
      }
      return { ...item };
    });
    set((prevState) => ({ ...prevState, items }));
  },

  collapseItem: (itemName: NavItemName) => {
    const items = get().items.map((item) => {
      if (item.name === itemName) {
        return { ...item, isExpanded: false };
      }
      return { ...item };
    });
    set((prevState) => ({
      ...prevState,
      items,
    }));
  },

  setIsMinimized: (isMinimized: boolean) => {
    set((prevState) => ({ ...prevState, isMinimized }));
  },
  reset: () => set(initialState),
  resetItems: () => {
    for (const item of get().items) item.isExpanded = false;
    set(get());
  },
  configureNavContext: ({ role, isMobile, isSuperOrg }) => {
    let items = determineItemsBasedOnRole(isSuperOrg, role);
    let extendedItems: NavItem[] = [];
    if (isMobile && items.length > 4) {
      [items, extendedItems] = [items.slice(0, 3), items.slice(3)];
      extendedItems = expandSubNavItems(extendedItems);
    }

    set({ items, extendedItems });
  },
}));

function expandSubNavItems(extendedItems: NavItem[]): NavItem[] {
  for (let i = 0; i < extendedItems.length; i++) {
    const item = extendedItems[i];
    if (item.subNavItems) {
      const subNavItems = item.subNavItems.map(
        (subNavItem) => new NavItem(subNavItem.name as NavItemName)
      );
      extendedItems.splice(i, 1, ...subNavItems);
      break; // Assuming only one expanded item needs to be handled
    }
  }
  return extendedItems;
}
