import { useOrgs } from "backend/resources/orgRole";

import type { UserWithRole } from "backend/resources/userRole";
import { useUsersInNetwork } from "backend/resources/userRole";
import type { NetworkRoleType } from "backend/resources/userRole/types";
import { OrgRoleType } from "backend/resources/userRole/types";
import { useActiveNetworkId } from "state/network/network";
import { useActiveOrganizationIds } from "state/organization/organization";

export function getUserInitials(planEntryAuthor: any, defaultUser: any) {
  if (planEntryAuthor && planEntryAuthor.id !== defaultUser?.id) {
    const firstNameInitial = planEntryAuthor.first_name
      ? planEntryAuthor.first_name.slice(0, 1).toLocaleUpperCase()
      : "";

    const lastNameInitial = planEntryAuthor.last_name
      ? planEntryAuthor.last_name.slice(0, 1).toLocaleUpperCase()
      : "";

    const initials = `${firstNameInitial}${lastNameInitial}`;

    return initials || "Unknown"; // You can change 'Unknown' to whatever fallback text you want
  } else {
    return "Me";
  }
}

export const useUsersInActiveNetworkAndOrganization = (networkId?: string) => {
  const activeNetworkId = useActiveNetworkId();
  const activeOrganizationIds = useActiveOrganizationIds();
  // Queries
  const { data: usersInNetwork } = useUsersInNetwork(
    networkId ?? activeNetworkId
  );
  const { ownOrgIdentities } = useOrgs();
  // Consts
  const usersInOrg: UserWithRole[] =
    ownOrgIdentities
      ?.filter((organizationRole) =>
        activeOrganizationIds?.includes(organizationRole.organization_id)
      )
      .map((role) => ({
        user: role.user,
        role: role.role as NetworkRoleType,
      })) ?? [];

  // dedupe the combination
  const usersInOrgAndNetwork: UserWithRole[] = [
    ...(usersInNetwork ?? []),
    ...usersInOrg,
  ].reduce((acc: UserWithRole[], user: UserWithRole) => {
    if (!acc.find((u) => u.user?.id === user.user?.id)) {
      acc.push(user);
    }
    return acc;
  }, []);

  const orgUsersInNetwork =
    usersInNetwork?.filter((user) => isOrgRole(user.role)) ?? [];

  return {
    usersInOrg,
    usersInNetwork,
    usersInOrgAndNetwork,
    orgUsersInNetwork,
  };
};

function isOrgRole(role: NetworkRoleType | OrgRoleType): role is OrgRoleType {
  return Object.values(OrgRoleType).includes(role as OrgRoleType);
}
