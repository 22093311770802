import { useNavigate } from "react-router-dom";

export enum SharedRoute {
  ROOT = "/",
  LOGIN = "/log-in",
  HOME = "/home",
  TERMS_OF_SERVICE = "/terms-of-service",
  COOKIE_USE = "/cookie-use",
  PRIVACY_POLICY = "/privacy-policy",
  RESET_PASSWORD = "/reset-password",
  UPDATE_PASSWORD = "/update-password",
  DELETED = "/deleted",
  ACCEPT_INVITATION = "/accept-invitation",
  VIEW_INVITATION = "/view-invitation/:id",
  INVITATION_INVALID = "/invitation_invalid/:error",
  TEAM_EDIT_TEAM_MEMBER = "/team/edit-team-member/:id",
  WILDCARD = "*",
  SERVICE_REQUEST_NEW = "/service-request/new",
  SERVICE_REQUEST_VIEW = "/service-request/:id",
  SERVICE_REQUEST_EDIT = "/service-request/:id/edit",
  SERVICE_PAGE = "/service/:id",
  SERVICE_PROVIDER_PAGE = "/service-provider/:id",
  ADMIN = "/admin",
  MY_ACCOUNT = "/my-account",
  RECOMMENDATION_EDIT = "/carepilot/recommendation/edit/:id",
  MFA = "/mfa",
}

export enum CarePilotRoute {
  RECOMMENDATION = "/carepilot/recommendation/:id",
  INTERVENTION = "/carepilot/intervention/:id",
  VIEW_ALL_RECOMMENDATION = "/carepilot/view-all-recommendation",
  PLAN = "/carepilot/plan",
  PLAN_NEW = "/carepilot/plan/new",
  PLAN_ID = "/carepilot/plan/:id",
  PLAN_NEW_TASK = "/carepilot/plan/new/:task",
  PLAN_EDIT = "/carepilot/plan/edit/:id",
  RECOMMENDATION_ADD = "/carepilot/recommendation/add",
  RECOMMENDATION_ADD_ID = "/carepilot/recommendation/add/:id",
  MY_CARE = "/carepilot/my-care",
  MY_CARE_ID = "/carepilot/my-care/:id",
  GOAL_ID = "/carepilot/goal/:id",
  GOAL_EDIT_ID = "/carepilot/goal/edit/:id",
  GOAL_EDIT = "/carepilot/goal/edit",
  // TODO: PR-2794: Remove CareBinder completely
  BINDER = "/carepilot/binder",
  CARE_PLAN_EDIT = "/carepilot/care-plan/plan/edit",
  FAVORITES = "/carepilot/favorites",
  BOOKMARK = "/carepilot/bookmark/:id",
  FAMILY_HUB = "/carepilot/family-hub",
  SETTINGS = "/carepilot/settings",
  LOCAL_SEARCH = "/carepilot/local-search",
  EDUCATION = "/carepilot/education",
  EDUCATION_CATEGORY = "/carepilot/education-category/:category",
  GENERAL_DISCUSSION = "/carepilot/general-discussion",
  CONVERSATION = "/carepilot/conversation/:conversation_id",
  CARE_PLAN = "/carepilot/care-plan",
  ASSESSMENTS = "/carepilot/assessments",
  RECOMMENDATION_EDIT = "/carepilot/recommendation/edit/:id",
}

export enum CareCentralRoute {
  PAF_SUBMISSION_VIEW = "/carecentral/paf-submission/:id",
  PAF_SUBMISSION_EDIT = "/carecentral/paf-submission/edit/:id",
  CARESPACES = "/carespaces",
  SERVICE_HUB = "/service-hub",
  GUIDE_NAVIGATOR = "/carecentral/guide-navigator",
  GUIDE_CATEGORY = "/carecentral/guide-category/:category/:network_id",
  VIEW_TASK = "/carecentral/tasks/:id",
  NEW_TASK = "/carecentral/tasks/new",
  DISCUSSIONS = "/carecentral/discussions",
  ORG = "/carecentral/org",
  SHAREABLE_RECOMMENDATION_ADD = "/carecentral/shareable-recommendation/add",
  SHAREABLE_RECOMMENDATION_ADD_ID = "/carecentral/shareable-recommendation/add/:id",
  SHAREABLE_RECOMMENDATION = "/carecentral/shareable-recommendation/:id",
  SHAREABLE_INTERVENTION = "/carecentral/shareable-intervention/:id",
  SHAREABLE_INTERVENTION_ADD = "/carecentral/shareable-intervention/add",
  SHAREABLE_INTERVENTION_ADD_ID = "/carecentral/shareable-intervention/add/:id",
  CAREGIVER_EDIT = "/carecentral/caregiver/edit/:caregiverInvitationId",
  CARESPACE = "/carecentral/carespace/:carespaceId",
  NOTES = "/carecentral/notes/:adloId",
  GOAL_TEMPLATE_ID = "/carecentral/goal-template/:id",
  GOAL_TEMPLATE_EDIT = "/carecentral/goal-template/edit",
  GOAL_TEMPLATE_EDIT_ID = "/carecentral/goal-template/edit/:id",
  ADD_NEW_PROVIDER = "/carecentral/add-new-provider",
  ADD_NEW_PROVIDER_MANUALLY = "/carecentral/add-new-provider-manually",
  DIRECTORY = "/carecentral/directory",
  BILLING_PAGE = "/carecentral/billing",
  INTERVENTION_EDIT = "/carecentral/intervention/edit/:id/:user_recommendation_id",
  INTERVENTION_CREATE = "/carecentral/intervention/create/:user_recommendation_id",
}

export enum SuperSuperUserRoute {
  PATIENT_SEARCH = "/super-super-user/patient-search",
  ORGANIZATIONS = "/organizations",
  ORGANIZATIONS_NEW = "/organizations/new",
  MEMBERS = "/members",
  MEMBERS_NEW = "/members/new",
}

export enum ExternalRoute {
  PRIVACY_POLICY = "https://www.craniometrix.com/privacy-policy",
  TERMS_OF_SERVICE = "https://www.craniometrix.com/terms-of-service",
  COOKIES_POLICY = "https://www.craniometrix.com/cookie-policy",
}

type RoutePath =
  | SharedRoute
  | CarePilotRoute
  | CareCentralRoute
  | SuperSuperUserRoute;

/**
 *  createPath() is a utility function for generating paths from the RoutePath enum.
 */
export function createPath(args: TArgs) {
  let path = args?.path?.toString();
  // Create a path by replacing params in the route definition
  if ("params" in args) {
    path = Object.entries(args.params).reduce(
      (previousValue: string, [param, value]) =>
        previousValue.replace(`:${param}`, `${value}`),
      args.path
    );
  }
  // Append query parameters if they exist
  if (args.queryParams) {
    const queryString = Object.entries(args.queryParams)
      .filter(([_, value]) => !!value)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value || "")}`
      )
      .join("&");
    path += `?${queryString}`;
  }

  return path ?? "";
}

/**
 * useAppNavigate() is a custom hook that wraps the useNavigate() hook from `react-router-dom`.
 * It returns a function that takes a TArgs object and navigates to the path generated by createPath(args).
 */
export function useAppNavigate() {
  const navigate = useNavigate();
  return (args: TArgs, options?: { replace?: boolean }) =>
    navigate(createPath(args), options);
}

/**
 * useNavigateBack() is a custom hook that wraps the useNavigate() hook from `react-router-dom`
 */
export function useNavigateBack() {
  const navigate = useNavigate();
  return () => navigate(-1);
}

/**
 *  Docs refs for the TypeScript features used in the args types:
 *  - Distributive Conditional Types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 *  - Conditional Types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
 *  - Template Literal Types: https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
 *
 *  See also: https://www.smashingmagazine.com/2021/01/dynamic-static-typing-typescript/
 */

type ParseRouteParams<Route> = string extends Route
  ? never // <- stripped out by TypeScript in the final Union type between all these options
  : Route extends `${string}/:${infer P1}/${infer Rest}` // <- if there is a param and other segments after it
  ? P1 | ParseRouteParams<`${string}/${Rest}`> // <- recursive step
  : Route extends `${string}/:${infer P}`
  ? P
  : never;

type RouteParams = {
  [Key in RoutePath]: Key extends `${string}/:${infer P}` // <- if there's at least one param
    ? { path: Key; params: Record<ParseRouteParams<Key>, string> } // <- recursively formed "A | B | C ..." as key of Record = dynamically generated required params
    : { path: Key };
};

type TArgs = (RouteParams[keyof RouteParams] | { path?: never }) & {
  queryParams?: Record<string, string | null>;
};
